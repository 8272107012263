import React ,{useState}from 'react'
import '../../admin/cards/card.css'
import { useNotify,useRedirect, SimpleForm, ImageInput, ImageField } from 'react-admin'
import axios from 'axios';
import { NODE_API } from '../../../../config';
import { Template_IMAGE_API } from '../../../../config';

export default function EditTemplate() {
  const notify = useNotify();
  const redirect = useRedirect();
  const [state, setState] = useState(0);

    const bgs = document.querySelectorAll('.backgroundsadmin img');
        let activeBg = 0; //default background
        bgs.forEach((item, i) => {
            item.addEventListener('click', () => {
                setState(i)
                bgs[activeBg].classList.remove('active');
                item.classList.add('active');
                activeBg = i;
            })
        })

        const onsubmit = async (data) => {
            if (data && data.templateimg) {
                var file = data.templateimg
                const reader = new FileReader();
                reader.readAsDataURL(file.rawFile);
                reader.onload = async () => await editOrganization(reader.result, state)
                reader.onerror = () => notify(`Something went wrong`, { type: 'warning' })
              } else {
          
                notify(`Image should not be greater than 50kb`, { type: 'warning' })
          
              }
        }

        async function editOrganization(logoData, number) {
            let user = JSON.parse(sessionStorage.getItem('user'));
            let token, reqData;;
        
            if (user && user.validator) {
              token = user.validator
            } else {
              token = {}
            }
        
              reqData = {
                timage: logoData,
                number:number
           
            }
        
            const requestOptions = {
              method: 'PUT',
              headers:
              {
                'Content-Type': 'application/json',
                'Authorization': token
              },
              data: JSON.stringify(reqData)
        
            };
        
            return axios(`${NODE_API}/organization/template`, requestOptions)
        
              .then(async res => {
                if (res.status && res.status === 200) {
                  notify(`Template successfully Updated`, { type: 'success' })
                  redirect('/')
                }
                else {
                  notify(`Something went wrong try again later`, { type: 'warning' })
                  redirect('/')
                }
        
                return res;
              }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
              });
          }
        
    return (
        <>
            <div className="backgroundsadmin" style={{ width: "100%" }}>
                <img src={`${Template_IMAGE_API}/img0.png?${new Date()}`} className="active" alt="" />
                <img src={`${Template_IMAGE_API}/img1.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img2.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img3.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img4.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img5.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img6.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img7.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img8.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img9.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img10.png?${new Date()}`} alt="" />
                <img src={`${Template_IMAGE_API}/img11.png?${new Date()}`} alt="" />
            </div>
            <div style={{ width: "100%" }} >
                <SimpleForm save={onsubmit}>
                    <ImageInput
                        source='templateimg'
                        label="Template image" accept="image/png"
                        maxSize="50000"
                        placeholder="Drop a image to update, or click to select it.Image should not greater than 50kb"
                    >
                        <ImageField source="thumbnail" width="10%" title="title" />

                    </ImageInput>


                    <p style={{ color: "#eed202", fontSize: "70%" }}>* Image should not be greater than 50kb</p>

                </SimpleForm>

            </div>
        </>
    )
}
