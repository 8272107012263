import React from "react";
import { useDataProvider, minLength, Create, SaveButton, ImageInput, ImageField, TextInput, SimpleForm, regex, List, required, useNotify, useRedirect, SelectInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { NODE_API } from '../../../../config';
import { COUNTRY } from "../../../../constants/countries";

export const styles = {
  name: { display: "inline-block" },
  domain: { display: "inline-block", marginLeft: 32 },
  country: { display: "inline-block", marginLeft: 32, marginTop: 20 },
  website: { width: 544 },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
};

const useStyles = makeStyles(styles);

const nameValidation = (value) => {
  var checkNull = value.replaceAll(/\s/g, '')
  if (!value || checkNull == "") {
    return 'should not be null';
  }
  //  
  return undefined;
};
const validatename = [required(), nameValidation]
const validateimg = [required()]
const validatewebsite = [regex(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/, 'Must be a valid website'), required()];
const validatedomain = [regex(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/, 'Must be a valid domain'), required()];

const CreateOrganization = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  async function createOrganization(logoData, organizationName, organizationDetails) {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;

    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    const requestOptions = {
      method: 'POST',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: JSON.stringify({
        name: organizationDetails.organizationname,
        domain: organizationDetails.organizationdomain,
        website: organizationDetails.organizationwebsite,
        address: organizationDetails.organizationaddress,
        city: organizationDetails.organizationcity,
        locationName: organizationDetails.locationname,
        country: organizationDetails.country,
        myFile: logoData
      })

    };

    // requestOptions
    return axios(`${NODE_API}/organization/create?name=${organizationName}`, requestOptions)

      .then(async res => {
        if (res.data.status && res.data.status === "Database error") {
          notify(`Organization already register`, { type: 'warning' })
        }
        else if (res.data.status && res.data.status === "Company successfully registered") {
          notify(`Organization successfully registered`, { type: 'success' })
          redirect('/');

        }
        else {
          notify(`Something went wrong try again later`, { type: 'warning' })
          redirect('/');


        }

        return res;
      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })
        redirect('/');

      });
  }




  const onsubmit = (data) => {

    var file = data.organizationimg
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    var organizationName = (data.organizationname).replace(/\s/g, "");
    reader.onload = async () => await createOrganization(reader.result, organizationName, data)
    reader.onerror = () => notify(`Something went wrong`, { type: 'warning' })

  };
  return isXSmall && isSmall ? (

    <SimpleForm save={onsubmit}>
      <Typography variant="h6" gutterBottom>
        Create Organization
      </Typography>
      <TextInput
        autoFocus
        source="organizationname"
        label="organization name"
        validate={validatename}
        fullWidth={true}
      />
      <TextInput
        source="organizationdomain"
        label="organization domain"
        validate={validatedomain}
        fullWidth={true}
      />
      <TextInput
        source="organizationwebsite"
        label="organization website"
        fullWidth={true}
        validate={validatewebsite}
      />
      <Typography variant="h6" gutterBottom>
        Create Organization
      </Typography>
      <TextInput
        source="locationname"
        label="location name"
        validate={validatename}
        fullWidth={true}
      />

      <TextInput
        source="organizationcity"
        label="organization city"
        validate={validatename}
        fullWidth={true}
      />
      <SelectInput
        source="country"
        label="Select Country"
        required={true}
        fullWidth={true}
        choices={COUNTRY}
        validate={required()}
      />
      <TextInput
        source="organizationaddress"
        label="organization address"
        validate={validatename}
        fullWidth={true}
      />
      <ImageInput
        source="organizationimg"
        label="organization logo" accept="image/png"
        maxSize="50000"
        placeholder="Drop a logo to upload, or click to select it.logo should not greater than 50kb"
        validate={validateimg}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <p style={{ color: "green", fontSize: "70%" }}>* Logo should not be greater than 50kb</p>

    </SimpleForm>

  )
    :
    (

      <SimpleForm save={onsubmit}>
        <Typography variant="h6" gutterBottom>
          Create Organization
        </Typography>
        <TextInput
          autoFocus
          source="organizationname"
          label="organization name"
          formClassName={classes.name}
          validate={validatename}
        />
        <TextInput
          source="organizationdomain"
          label="organization domain"
          formClassName={classes.domain} validate={validatedomain} />
        <TextInput

          source="organizationwebsite"
          label="organization website"
          fullWidth={true}
          formClassName={classes.website}
          validate={validatewebsite}
        />
        <Typography variant="h7" gutterBottom>
          Location
        </Typography>

        <TextInput
          source="locationname"
          label="location name"
          formClassName={classes.name}
          validate={validatename}
        />

        <TextInput
          source="organizationcity"
          label="organization city"
          formClassName={classes.domain}
          validate={validatename}
        />

        <SelectInput
          source="country"
          label="Select Country"
          required={true}
          fullWidth={true}
          choices={COUNTRY}
          formClassName={classes.website}
          validate={required()}

        />

        <TextInput
          source="organizationaddress"
          label="organization address"
          fullWidth={true}
          formClassName={classes.website}
          validate={validatename} />

        <ImageInput
          source="organizationimg"
          label="organization logo"
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a logo to upload, or click to select it"
          validate={validateimg}>

          <ImageField source="src" title="title" />
        </ImageInput>
        <p style={{ color: "#eed202", fontSize: "70%" }}>* Logo should not be greater than 50kb</p>

      </SimpleForm>
    );
};


export default CreateOrganization;