import React  from "react";
import {
  TextInput,
  SimpleForm,
}
  from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { IMAGE_API } from '../../../../config';



export const styles = {
  name: { display: "inline-block", color: 'black',textColor:"black" },
  city: { display: "inline-block", marginLeft: 32, color: 'black' },
  website: { width: 544 },
  domain: { width: 544 },
  form: {
    marginLeft: 32,
    display: 'flex',
    justifyContent: 'center'
  }
};

const useStyles = makeStyles(styles);


const OrganizationDetails = (props) => {

  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const { name, domain, website, city } = props.data

  var trimName = name.replace(/\s/g, '')

  return isXSmall && isSmall ? (

    <SimpleForm toolbar={false}>
      <Typography  variant="h6" gutterBottom>
        Organization Details
      </Typography>

      <img style={{ objectFit: 'scale-down'}} src={`${IMAGE_API}/${trimName}/${trimName}.png`} height={200} width={100} className="rounded-circle" ></img>

        {/* <ImageField source='logo' fullWidth={false} src={`${IMAGE_API}/${trimName}/${trimName}.png`} /> */}

      <TextInput
        disabled
        InputProps={{ inputProps: { style: { color: 'black' }}}}
        defaultValue={name}
        source="organizationname"
        label="organization name"
      />

      <TextInput
        disabled
        InputProps={{ inputProps: { style: { color: 'black' }}}}
        defaultValue={domain}
        source="organizationdomain"
        label="organization domain"
      />

      <TextInput
        disabled
        InputProps={{ inputProps: { style: { color: 'black' }}}}
        defaultValue={website}
        source="organizationwebsite"
        label="organization website"
      />

      <TextInput
        disabled
        InputProps={{ inputProps: { style: { color: 'black' }}}}
        defaultValue={city}
        source="organizationcity"
        label="organization city"
      />

    </SimpleForm>

  ) :
    (


      <SimpleForm  toolbar={false} style={{ marginLeft: 30, marginTop:50 }}>
        <Typography variant="h6" gutterBottom>
          Organization Details
        </Typography>

        <img style={{ objectFit: 'scale-down',  marginRight:'35%'}} src={`${IMAGE_API}/${trimName}/${trimName}.png`} height={200} width={100} className="rounded-circle float-right" ></img>

        <TextInput
          InputProps={{ inputProps: { style: { color: 'black' }}}}
          disabled
          defaultValue={name}
          source="organizationname"
          label="organization name"
          formClassName={classes.name}
        />

        <TextInput
          disabled
          InputProps={{ inputProps: { style: { color: 'black' }}}}
          defaultValue={city}
          source="organizationcity"
          label="organization city"
          formClassName={classes.city}
        />

        <TextInput
          disabled
          InputProps={{ inputProps: { style: { color: 'black' }}}}
          defaultValue={domain}
          source="organizationdomain"
          label="organization domain"
          fullWidth={true}
          formClassName={classes.domain}
        />

        <TextInput
          disabled
          InputProps={{ inputProps: { style: { color: 'black' }}}}
          defaultValue={website}
          source="organizationwebsite"
          label="organization website"
          fullWidth={true}
          formClassName={classes.website}
        />



      </SimpleForm>

    );
};



export default OrganizationDetails;