import React, { useState, useCallback, useEffect } from "react";
import {
    useVersion,
    email,
    useDataProvider,
    TextInput,
    SimpleForm,
    required,
    useNotify,
    useRedirect,

} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import axios from 'axios';
import { NODE_API } from '../../../../config'


export const styles = {
    name: { display: "inline-block" },
    domain: { display: "inline-block", marginLeft: 32 },
    website: { width: 544 },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

const useStyles = makeStyles(styles);

const nameValidation = (value) => {
    var checkNull = value.replaceAll(/\s/g, '')
    if (!value || checkNull == "") {
        return 'Value should not be null';
    }
    //  
    return undefined;
};
const validateemail = [required(), email(), nameValidation]


const CreateAdmin = (props) => {
    const [state, setState] = useState({});
    const [buttoncheck, setStateButton] = useState();
    const redirect = useRedirect();
    const notify = useNotify();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();

    //Get counts data for chart
    async function createadmin(data) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: JSON.stringify({
                name: data.adminOrganization,
                email: data.adminEmail,
            })

        };

        // requestOptions
        return axios(`${NODE_API}/organization/admin`, requestOptions)

            .then(async res => {
                setStateButton()

                if (res && res.status === 208) {

                    notify(`Admin already register`, { type: 'warning' })
                }
                else if (res && res.status === 200) {
                    notify(`Admin successfully added`, { type: 'success' })
                    redirect('/');

                }
                else {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                    redirect('/');


                }

                return res;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
                redirect('/');

            });
    }


    const handleInput = (e) => {
        setState(state => ({

            ...state,

            organizationname: e.value


        }))
    }

    useEffect(() => {
        fetchList()
    }, [version])

    //Get list of organizations
    const fetchList = useCallback(async () => {
        let data = []

        await dataProvider.getList(
            'organization/admin/list',
            {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'numberUser', order: 'DESC' }
            }
        )
            .then(res => {

                res.data.map(function (element) {
                    data.push({ "label": element.label, "value": element.value })

                })

            })
            .catch(e => console.log('error : ' + e));
        ;

        setState(state => ({

            ...state,

            organizationList: data

        }));
    }, [dataProvider])

    const { organizationList } = state

    const onsubmit = (data) => {
        if (state.organizationname === undefined || state.organizationname === null) {

            notify(`Please check form.Organization name is mandotory`, { type: 'warning' })

        }
        else {
            setStateButton(false)
            var senddata = {
                adminEmail: data.adminemail,
                adminOrganization: state.organizationname
            }
            createadmin(senddata)
        }

    };
    return (
        < SimpleForm save={onsubmit} toolbar={buttoncheck}>
            <Typography variant="h6" gutterBottom>
                Create Organization Admin
            </Typography>

            <TextInput
                autoFocus
                source="adminemail"
                label="Enter admin email"
                fullWidth={true}
                formClassName={classes.email}
                validate={validateemail}
            />

            <Select
                placeholder='Organization name'
                source="adminname"
                label="Organization name"
                options={organizationList}
                onChange={handleInput}
            />


        </SimpleForm >
    )

};



export default CreateAdmin;


