import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider, TextInput, SimpleForm, required, useNotify, useRedirect, Edit, useVersion } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { NODE_API } from '../../../../config';
import CardDetailsForm from "./CardDetailsForm";
import { OTPGenerator, OTPEncryption } from '../../../../utils/encrypt'
import EditCardDetails from "./EditCardDetails";
import { useHistory } from "react-router";


export const styles = {

    refer: { width: 544 },
    city: { width: 544 },
    address: { width: 544 },
    singleCol: { marginTop: '2em', marginBottom: '2em' },

};

const useStyles = makeStyles(styles);

const validatecity = [required()]

const ValidateCorporateDomain = (props) => {

    const redirect = useRedirect();
    const notify = useNotify();
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();
    const navigation = useHistory()

    //check if entered domain is valid and then send mail 
    async function checkDomainAndSendMail(encryptedKey, data) {

        let user = JSON.parse(sessionStorage.getItem('user'));

        let token;

        let organizationID;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: JSON.stringify({
                key: encryptedKey,
                email: data.email
            })
        };

        return axios(`${NODE_API}/organization/domain/mail`, requestOptions)

            .then(async res => {
                if (res.status && res.status === 208) {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                }
                else if (res && res.status === 200 && res.data.status == "alreadyexist") {
                    notify(`This email is already connect to organization `, { type: 'warning' })

                }
                else if (res && res.status === 200) {


                    notify(`Check your mail for OTP`, { type: 'success' })

                    res.data.map(element => {
                        organizationID = element.id
                    })

                    setState(state => ({

                        ...state,

                        validMail: true,

                        organizationDetails: res.data,

                        organizationID: organizationID,

                        corporateMail: data.email

                    }));

                }
                else if (res && res.status === 204) {
                    notify(`Enter valid mail address`, { type: 'warning' })
                }
                else {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                }

                return res;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });
    }

    const hasOrganizationFun = useCallback(async () => {

        let newOrgReq = true;

        if (navigation && navigation.location && navigation.location.state && navigation.location.state[0] == 'new_organization') {
            newOrgReq = false
        }

        let user = JSON.parse(sessionStorage.getItem('user'));

        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'GET',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        axios(`${NODE_API}/organization/corporate/user/exist`, requestOptions)

            .then(async res => {

                if (res && res.status === 200) {

                    //------------------Need to change logic--------------
                    if (res.data.length == 1) {
                        res.data.forEach(ele => {
                            if (ele.is_approved == null) {
                                newOrgReq = true
                            }
                        })
                    }

                    setState(state => ({

                        ...state,

                        hasOrganization: newOrgReq,

                        organizationDetails: res.data,

                    }))
                }
                else if (res && res.status === 204) {
                    setState(state => ({

                        ...state,

                        hasOrganization: false,

                    }))

                }
                else {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                    redirect('/')
                }

                return res;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });

    }, [dataProvider])


    useEffect(() => {
        hasOrganizationFun()

    }, [version])



    // send OTP mail
    const onsubmit = async (data) => {


        var generatedOTP = OTPGenerator()

        setState(state => ({

            ...state,

            generatedOTP: generatedOTP,

        }))

        var encryptedKey = OTPEncryption(generatedOTP).toString()

        await checkDomainAndSendMail(encryptedKey, data)

    };

    // check if entered OTP is Correct
    const onOTPSubmit = async (data) => {

        let generatedOTP = String(state.generatedOTP)

        if (generatedOTP === data.otp) {

            setState(state => ({

                ...state,

                OTPSuccess: true,

            }))


        }
        else {
            notify(`Enter valid mail OTP`, { type: 'warning' })

            setState(state => ({

                ...state,

                OTPSuccess: false,

            }))
        }

    };

    const { organizationDetails, corporateMail, hasOrganization, validMail, OTPSuccess, organizationID } = state


    return isXSmall && isSmall ? (

        <>

            {
                hasOrganization ?
                    <>
                        <EditCardDetails organizationDetails={organizationDetails} />
                    </> :
                    ((validMail && !OTPSuccess) ?

                        (
                            <SimpleForm save={onOTPSubmit}>
                                <Typography variant="h6" gutterBottom>
                                    Organization Form
                                </Typography>
                                <TextInput
                                    required={true}
                                    source="email"
                                    type="email"
                                    autoFocus
                                    fullWidth={true}
                                    label="Enter Corporate Mail ID"

                                    disabled
                                />
                                <TextInput
                                    required={true}
                                    source="otp"
                                    autoFocus
                                    fullWidth={true}
                                    label="Enter OTP"

                                />
                            </SimpleForm>
                        ) : ((validMail && OTPSuccess && corporateMail) ?

                            <>
                                <CardDetailsForm organizationID={organizationID} corporateMail={corporateMail} organizationList={organizationDetails} />
                            </>
                            :
                            <SimpleForm save={onsubmit}>
                                <Typography variant="h6" gutterBottom>
                                    Organization Form
                                </Typography>
                                <TextInput
                                    required={true}
                                    source="email"
                                    type="email"
                                    autoFocus
                                    fullWidth={true}
                                    label="Enter Corporate Mail ID"
                                    validate={validatecity}
                                />
                            </SimpleForm>

                        )
                    )
            }


        </>

    ) :

        (
            <>
                {
                    //if employee already has registered to organization then send him to edit card request
                    hasOrganization ?
                        <>
                            <EditCardDetails organizationDetails={organizationDetails} />
                        </> :
                        (
                            // show with otp div if otp is incorrect
                            (validMail && !OTPSuccess) ?

                                (
                                    <SimpleForm save={onOTPSubmit}>
                                        <Typography variant="h6" gutterBottom>
                                            Organization Form
                                        </Typography>
                                        <TextInput
                                            required={true}
                                            source="email"
                                            type="email"
                                            autoFocus
                                            fullWidth={true}
                                            label="Enter Corporate Mail ID"
                                            formClassName={classes.refer}
                                            disabled
                                        />
                                        <TextInput
                                            required={true}
                                            source="otp"
                                            autoFocus
                                            fullWidth={true}
                                            label="Enter OTP"
                                            formClassName={classes.refer}
                                        />
                                    </SimpleForm>
                                ) :
                                (
                                    //if mail and otp is valid render organization regestration form 
                                    (validMail && OTPSuccess && corporateMail) ?
                                        <>
                                            <CardDetailsForm organizationID={organizationID} corporateMail={corporateMail} organizationList={organizationDetails} />
                                        </>
                                        :

                                        <SimpleForm save={onsubmit}>
                                            <Typography variant="h6" gutterBottom>
                                                Organization Form
                                            </Typography>
                                            <TextInput
                                                required={true}
                                                source="email"
                                                type="email"
                                                autoFocus
                                                fullWidth={true}
                                                label="Enter Corporate Mail ID"
                                                formClassName={classes.refer}
                                                validate={validatecity}
                                            />
                                        </SimpleForm>

                                )
                        )
                }

            </>
        );
};



export default ValidateCorporateDomain;