import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
    Button,
    Datagrid,
    EmailField,
    NumberField,
    TextField,
    useDataProvider,
    useRecordContext,
    useVersion,
} from "react-admin";
import {
    makeStyles,
    useMediaQuery,
    Divider,
    Tabs,
    Tab
} from "@material-ui/core";

import MobileGrid from "./MobileGrid";
import BulkReplaceUser from "./BulkReplaceUser";
import { ReplaceButton } from "./ReplaceButton";





const useDatagridStyles = makeStyles({
    total: { fontWeight: "bold" },
    centerText: {
        textAlign: 'center',
    },
});





class TabbedDatagrid extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            T: [],
            F: [],
        };
    }

    tabs = [
        { id: "F", name: "User List" },   //T means emp has quitted

        { id: "T", name: "Removed Users" },  //F means emp has not been quitted

    ];


    static getDerivedStateFromProps(props, state) {

        const { data, filterValues } = props

        const obj = props.data
        var map = Object.keys(obj);

        var idsStatus = []

        if (data) {

            map.forEach(ele => {

                if (filterValues.is_quit === data[`${ele}`].is_quit) {

                    idsStatus.push(data[`${ele}`].id)

                }

            })

            return { [props.filterValues.is_quit]: idsStatus };

        } else {
            return ('No Requests')
        }
    }


    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;

        setFilters({ ...filterValues, is_quit: value });
    };

   


    render() {

        const { isXSmall, classes, role, filterValues, ...props } = this.props;

        return (
            <>
                {role == 'LOCATION_ADMIN' ?
                    <Fragment>
                        <Tabs
                            variant="fullWidth"
                            centered
                            value={filterValues.is_quit}
                            indicatorColor="primary"
                            onChange={this.handleChange}>
                            {this.tabs.map(choice => (
                                <Tab key={choice.id} label={choice.name} value={choice.id} />
                            ))}
                        </Tabs>
                        <Divider />

                        <>

                            {isXSmall ?
                                (<MobileGrid {...props} ids={this.state[filterValues.is_quit]} />) :
                                (<div className="text-center">

                                    {filterValues.is_quit === "T" ?
                                        (
                                            this.state.T && this.state.T.length === 0 ?
                                                <h5 className="text-center m-4">No Records</h5> :

                                                <Datagrid
                                                    {...props}
                                                    ids={this.state.T}
                                                    optimized
                                                    hasBulkActions={false}
                                                    bulkActionButtons="false"
                                                    source='T'
                                                    className="text-center"
                                                >

                                                    <NumberField className={classes.centerText} source="employeeid" label="Employee ID" />
                                                    <TextField source="contactnumber" label="Phone" />
                                                    <TextField source="name" label="Name" />
                                                    <EmailField source="email" label="Email" />
                                                    <TextField source="designation" label="Designation" />
                                                    <TextField source="locname" label="location" />


                                                </Datagrid>
                                        ) : <></>
                                    }

                                    {filterValues.is_quit === "F" ?
                                        (
                                            this.state.F && this.state.F.length === 0 ?
                                                <h5 className="text-center m-4">No Records</h5> :
                                                <Datagrid {...props}
                                                    ids={this.state.F}
                                                    // hasBulkActions={false}
                                                    className="text-center"

                                                >
                                                    <NumberField source="employeeid" label="Employee ID" />
                                                    <TextField source="contactnumber" label="Phone" />
                                                    <TextField source="name" label="Name" />
                                                    <EmailField source="email" label="Email" />
                                                    <TextField source="designation" label="Designation" />
                                                    <TextField source="locname" label="location" />

                                                </Datagrid>
                                        ) : <></>
                                    }
                                </div>)}

                        </>

                    </Fragment>
                    : (role == 'CORPORATE_ADMIN' ?
                        <Fragment>
                            <Tabs
                                variant="fullWidth"
                                centered
                                value={filterValues.is_quit}
                                indicatorColor="primary"
                                onChange={this.handleChange}>
                                {this.tabs.map(choice => (
                                    <Tab key={choice.id} label={choice.name} value={choice.id} />
                                ))}
                            </Tabs>
                            <Divider />

                            <>

                                {isXSmall ?
                                    (<MobileGrid {...props} ids={this.state[filterValues.is_quit]} />) :
                                    (<div className="text-center">

                                        {filterValues.is_quit === "T" ?
                                            (
                                                this.state.T && this.state.T.length === 0 ?
                                                    <h5 className="text-center m-4">No Records</h5> :

                                                    <Datagrid
                                                        {...props}
                                                        ids={this.state.T}
                                                        optimized
                                                        hasBulkActions={false}
                                                        bulkActionButtons="false"
                                                        source='T'
                                                        className="text-center">

                                                        <NumberField className={classes.centerText} source="employeeid" label="Employee ID" />
                                                        <TextField source="contactnumber" label="Phone" />
                                                        <TextField source="name" label="Name" />
                                                        <EmailField source="email" label="Email" />
                                                        <TextField source="designation" label="Designation" />
                                                        <ReplaceButton {...props} />
                                                    </Datagrid>
                                            ) : <></>
                                        }

                                        {filterValues.is_quit === "F" ?
                                            (
                                                this.state.F && this.state.F.length === 0 ?
                                                    <h5 className="text-center m-4">No Records</h5> :
                                                    <Datagrid {...props}
                                                        ids={this.state.F}
                                                        className="text-center"
                                                        rowClick="edit"
                                                    >
                                                        <NumberField source="employeeid" label="Employee ID" />
                                                        <TextField source="contactnumber" label="Phone" />
                                                        <TextField source="name" label="Name" />
                                                        <EmailField source="email" label="Email" />
                                                        <TextField source="designation" label="Designation" />
                                                        <TextField source="organizationname" label="Organization" />
                                                    </Datagrid>
                                            ) : <></>
                                        }
                                    </div>)}

                            </>

                        </Fragment> : <></>
                    )}

               
            </>
        );
    }
}

const StyledTabbedDatagrid = props => {
    const classes = useDatagridStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();


    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;

        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        setState(state => ({

            ...state,

            role: role

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserRole()
    }, [version])

    return (
        <>
            {state && state.role &&
                <TabbedDatagrid role={state.role} classes={classes} isXSmall={isXSmall} {...props} />
            }
        </>
    )
};

StyledTabbedDatagrid.defaultProps = {
    textAlign: 'center',
}

export default StyledTabbedDatagrid;
