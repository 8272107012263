import React, { Component } from 'react';
import { Admin, createAdminStore, Resource } from 'react-admin';
import jsonServerProvider from "ra-data-json-server";
import './App.css';
import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Layout } from './layout';
import customRoutes from './routes';
import { createHashHistory } from 'history';
import { Provider } from 'react-redux';
import { fetchUtils } from "react-admin";
import { dashboard } from './dashboard';
import requests from './components/users/admin/requests';
import employees from './components/users/admin/employees';
import createorganization from './components/users/superAdmin/organization'
import ValidateReferCode from './components/users/user/organization'
import ValidateCorporateDomain from './components/users/user/Card/index'
import EditOrganization from './components/users/admin/organization'
import AddLocation from './components/users/admin/location'
import OrganizationList from './components/users/superAdmin/organization/OrganizationList'
import createadmin from './components/users/superAdmin/createadmin'
import DesignCard from './components/users/admin/cards/personal/DesignCard';
import ViewCard from './components/users/admin/cards/personal/ViewCard';
import corporateViewCard from './components/users/admin/cards/corporate/ViewCard';
import CorporateCard from './components/users/admin/cards/corporate/CorporateCard';
import { NODE_API } from './config'
import dataProviderFactory from './dataProvider';
import templates from './components/users/superAdmin/templates';
import Settings from './layout/Settings';

let user = JSON.parse(sessionStorage.getItem('user'));
let token;

if (user && user.validator) {
    token = user.validator
} else {
    token = {}
}


let component

if (user) {
    (user && user.type === 'SUPER_ADMIN') ? component = dashboard.SuperAdminDashboard :
        (user.type === 'CORPORATE_ADMIN' ? component = dashboard.CorporateAdminDashboard : component = dashboard.UserDashboard)
}


const fetchJson = (url, options = {}) => {

    options.user = {
        authenticated: true,
        token: token
    };
    return fetchUtils.fetchJson(url, options);

}


const history = createHashHistory();

class AdminPanel extends Component {
    state = { dataProvider: null };

    async componentDidMount() {

        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );

        this.setState({ dataProvider });
    }


    componentWillUnmount() {
        this.restoreFetch();
    }

    render() {

        const dataProvider = jsonServerProvider(`${NODE_API}`, fetchJson);


        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }

        return (

            <Provider
                store={createAdminStore({
                    authProvider,
                    dataProvider,
                    history,
                })}
            >

                {user ? <Admin
                    title=""
                    dataProvider={dataProvider}
                    customReducers={{ theme: themeReducer }}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    dashboard={component}
                    layout={Layout}
                    history={history}>

                    <Resource name="organization/users/cards/request" {...requests}
                        options={{ label: 'Requests' }} />

                    <Resource name="organization/users/list" {...employees}
                        options={{ label: 'Employee List' }} />

                    <Resource name="organization/location/users/cards/request" {...requests}
                        options={{ label: 'Requests' }} />

                    <Resource name="organization/location/info" {...AddLocation}
                        options={{ label: 'location List' }} />

                    <Resource name="createorganization" {...createorganization} />

                    <Resource name="edit-organization" {...EditOrganization} />

                    <Resource name="card-design" {...ValidateCorporateDomain} />

                    <Resource name="add-organization" {...ValidateReferCode} />

                    <Resource name="createadmin" {...createadmin} />

                    <Resource name="personal-card-view" list={ViewCard} />

                    <Resource name="personal-card-design" list={DesignCard} />

                    <Resource name="corporate-card-view" list={corporateViewCard} />

                    <Resource name="templates" {...templates} />

                    <Resource name="corporate-admin-card-design" list={CorporateCard} />


                    <Resource name="organization/lists" list={OrganizationList}
                        options={{ label: 'Organization List' }} />

                    <Resource name="settings" list={Settings} />
                    


                </Admin> :
                    <Admin
                        title=""
                        dataProvider={dataProvider}
                        customReducers={{ theme: themeReducer }}
                        customRoutes={customRoutes}
                        authProvider={authProvider}
                        layout={Layout}
                        history={history}>

                    </Admin>
                }

            </Provider>
        );
    }
}

export default AdminPanel;
