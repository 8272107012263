//List of new organization register in this month
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/Business';
import { makeStyles } from '@material-ui/core/styles';
import { useVersion, useDataProvider, useTranslate } from 'react-admin';
import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
        textAlign: 'right',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const NewOrganizations = () => {
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const featchOrganizations = useCallback(async () => {
        var sendData = [];


        await dataProvider.getList(
            'organization/list',
            {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'registration_date', order: 'DESC' }
            }
        )
            .then(res => {

                res.data.map(function (element) {
                    sendData.push({ "label": element.label, "value": element.value, "registration_date": element.registration_date, "img": element.img })
                })


            })
            .catch(e => console.log('error : ' + e));
        ;

        setState(state => ({

            ...state,


            organizationDetails: sendData,


        }));
    }, [dataProvider])

    useEffect(() => {
        featchOrganizations()
    }, [version])

    const { organizationDetails } = state

    const classes = useStyles();


    return (
        <div className={classes.main}>
            <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    Towards a Greener Earth
                </Typography>

            </Card>

            <Card className={classes.card}>
                <img src="/images/img/saveworld2.jfif" className="active" alt="" width={"100%"} style={{ padding: "4%" }} />            </Card>
        </div>
    );
};

export default NewOrganizations;
