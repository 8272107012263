import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  useDataProvider,
  useNotify,
  useVersion,
  SearchInput,
  useRedirect,
  Button,
  useRefresh,
  SimpleForm,
  SelectInput,
  useList
} from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';

import {
  Collapse,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import axios from 'axios';
import { NODE_API } from '../../../../config';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ExpandLess, ExpandMore, Search } from "@material-ui/icons";
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import CreateLocation from "./CreateLocation";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";

var userorganizationidd = ""
const useStyles = makeStyles((theme) => ({
  city: { width: 544 },

  address: { width: 544 },

  singleCol: { marginTop: '2em', marginBottom: '2em' },

  card: {
    padding: '16px 0',
    width: '85%',
  },
  cardMobile: {
    padding: '16px 0',
    width: '85%',
    marginLeft: '10%'
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  inline: {
    display: 'inline',
    marginTop: 0,
    color: '#0000008a',
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  addadmin: {
    '& > *': {
      margin: theme.spacing(1),
      width: '30ch',

    },
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(3),
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },

}));


const LocationList = props => {

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const version = useVersion();
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [state, setState] = useState({});
  const [open, setOpen] = React.useState(0);
  const [openDialoue, setOpenDialogue] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState({ email: '' });
  const history = useHistory();
  const refresh = useRefresh();

  const getOrgDetails = useCallback(async () => {

    let details = []

    let organizationUsers = []

    let adminDetails = []

    let sessionMailArray = []

    let user = JSON.parse(sessionStorage.getItem('user'));
    let token, sessionMail;


    if (user && user.validator && user.email) {
      token = user.validator
      sessionMail = user.email
    } else {
      token = {}
      sessionMail = ''
    }

    sessionMailArray.push({ id: sessionMail, name: sessionMail })

    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };

    await axios(`${NODE_API}/organization/location/info/${userorganizationidd}`, requestOptions)

      .then(res => {

        details = res.data

        details.adminMails.map(function (ele) {

          organizationUsers.push({
            email: ele.email,
            user_id: ele.user_id,
            id: ele.user_id,
            name: ele.email,
            label: ele.email,
            empName: ele.name,
            designation: ele.designation
          })

        })


        details.adminData.map(function (element) {

          adminDetails.push({
            id: element.id,
            city: element.city,
            country: element.country,
            corporateMail: element.corporateMail,
            name: element.name,
            admin: element.admin,
            address: element.address
          })

        })

        return res;

      }).catch(e => {

        notify(`Something went wrong try again later`, { type: 'warning' })

      });



    setState(state => ({

      ...state,
      organizationUsers,
      adminDetails,
      token: token,
      sessionMail: sessionMail,
      sessionMailArray: sessionMailArray

    }));

  }, [dataProvider])



  async function removeAdminFromLocation(adminmail, loc_id) {


    const requestOptions = {
      method: 'DELETE',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': state.token
      }

    };

    return axios(`${NODE_API}/organization/location/admin/${loc_id}/${adminmail}/${userorganizationidd}`, requestOptions)

      .then(async res => {

        if (res && res.status === 208) {
          notify(`Something went wrong try again later`, { type: 'warning' })
          refresh();
        }
        else if (res && res.status === 200) {
          notify(`Admin removed successfully`, { type: 'success' })
          refresh();
        }
        else {
          notify(`Something went wrong try again later`, { type: 'warning' })
          refresh();
        }

        return res;
      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })
      });
  }

  const userorganization = async function () {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;
    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }
    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };

    // requestOptions
    await axios(`${NODE_API}/organization/list/userorganization`, requestOptions)

      .then(res => {
        var organizationlist = []
        res.data.data.map(val => {
          if (val.type_id == 2) {
            organizationlist.push({ id: val.id, value: val.name, label: val.name, name: val.name })
            userorganizationidd = val.id
          }
        })

        setState(state => ({

          ...state,
          id: userorganizationidd,
          organizationlist: organizationlist

        }))


      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })

      });

  }
  const handleInputList = async (event) => {
    var idtarget = event.target.value
    userorganizationidd = idtarget
    setState(state => ({

      ...state,
      id: idtarget
    })
    )
    getOrgDetails()
  }
  useEffect(() => {
    userorganization().then(res => {
      getOrgDetails()
    })

  }, [version])


  const postFilters = [
    <SearchInput source="q" alwaysOn />
  ];


  const handleClick = (id) => {
    setOpen(id);
  };


  const handleClickOpen = (name, city, country, address) => {

    setState(state => ({

      ...state,

      name: name,

      city: city,

      country: country,

      address: address

    }));

    setOpenDialogue(true);
  };



  const onSelectDialogue = async (value) => {
    setOpenDialogue(false);
    setSelectedValue(value);
  };


  const handleCloseDrawer = useCallback(() => {
    history.push('/organization/location/info');

    setState(state => ({

      ...state,

      isDrawerOpen: false

    }));

  }, [history]);


  const enableDrawer = () => {


    setState(state => ({

      ...state,

      isDrawerOpen: true

    }));

  }


  const removeAdmin = async (email, loc_id) => {
    await removeAdminFromLocation(email, loc_id)
  };



  const { adminDetails, organizationUsers } = state


  return isXSmall && isSmall ? (

    <Fragment>
      {state.organizationlist && state.organizationlist.length > 1 ? (

        <SimpleForm toolbar={false}>
          <SelectInput
            placeholder='Select organization'
            source="orgSelection"
            label="Select organization"
            fullWidth={true}
            required={true}
            defaultValue={state.id}
            onChange={handleInputList}
            style={{ width: "500px" }}
            choices={state.organizationlist}
          />
        </SimpleForm>
      ) : <></>}
      <div style={{ marginTop: '3%' }}>
        <Card className={classes.cardMobile} >

          <Typography
            variant="h6"
            component="h2"

            className={classes.value}>
            Location List & Admin
            <div className="text-right mr-5 mb-2">

              <Button
                style={{ fontWeight: 'bolder' }}
                alignIcon="left" label="Create"
                onClick={enableDrawer}>
                <AddCircleOutlineIcon />
              </Button>

            </div>
          </Typography>

          <Divider />

          <List
            {...props}
            // actions={<ListActions />}                  
            exporter="false"
            filters={postFilters}
            style={{ maxHeight: '500px', overflow: 'auto' }}>

            {adminDetails && adminDetails.map(adminrec => (
              <>
                <ListItem
                  button
                  key={adminrec.id}
                  alignItems="flex"
                >
                  <ListItemText
                    primary={`${adminrec.name}`}
                    secondary={
                      <Typography
                        component="span"
                        variant="inherit"
                        className={classes.inline}
                        color="textSecondary"
                        align="flex-start"
                      >
                        {`\n${adminrec.city} \t ${adminrec.country}.\n`}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction >
                    <IconButton edge="end" aria-label="edit"
                      onClick={() => handleClickOpen(adminrec.name, adminrec.city, adminrec.country, adminrec.address)}>
                      <PersonAddIcon />
                      <span className="ml-3 p-1" style={{ fontSize: 'smaller', borderRadius: '50%' }}>{adminrec.corporateMail.length - 1} </span>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem
                  button
                  key={adminrec.id}
                  alignItems="flex"
                  onClick={() => handleClick(adminrec.id)}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textSecondary"
                        >
                          <h6>{`${adminrec.address}\n`}</h6>

                        </Typography>


                      </React.Fragment>
                    }
                  />
                  {open === adminrec.id ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={open === adminrec.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {adminrec.corporateMail && adminrec.corporateMail.map(mail => (

                      <>
                        {
                          mail && mail != state.sessionMail ?
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <PersonIcon />
                              </ListItemIcon>
                              <ListItemText primary={`\n${mail}`} />
                              <ListItemSecondaryAction >

                                {
                                  mail && mail == state.sessionMail ? <>you</> :
                                    <IconButton edge="end" aria-label="remove"
                                      onClick={() => removeAdmin(mail, adminrec.id)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                }

                              </ListItemSecondaryAction>
                            </ListItem> : <></>
                        }
                      </>
                    ))}
                  </List>
                </Collapse>
                <Divider />
              </>
            ))}

          </List>

          <Drawer
            variant="persistent"
            open={state.isDrawerOpen}
            anchor="right"
            onClose={handleCloseDrawer}>

            <CreateLocation sessionMailArray={state.sessionMailArray} userList={organizationUsers} onCancel={handleCloseDrawer} {...props} />

          </Drawer>

        </Card>

        <SimpleDialog data={state} sessionMail={state.sessionMail} userList={organizationUsers} selectedValue={selectedValue} open={openDialoue} onClose={onSelectDialogue} />

      </div>

    </Fragment>
  )
    :

    <Fragment>
      {state.organizationlist && state.organizationlist.length > 1 ? (

        <SimpleForm toolbar={false}>
          <SelectInput
            placeholder='Select organization'
            source="orgSelection"
            label="Select organization"
            fullWidth={true}
            required={true}
            defaultValue={state.id}
            onChange={handleInputList}
            style={{ width: "500px" }}
            choices={state.organizationlist}
          />
        </SimpleForm>
      ) : <></>}
      <div style={{ marginTop: '3%' }}>
        <Card className={classes.card} >

          <Typography
            variant="h5"
            component="h2"
            className={classes.value}>
            Location List & Admin
            <div className="text-right mr-5 mb-2">

              <Button
                style={{ fontWeight: 'bolder' }}
                alignIcon="left" label="Create"
                onClick={enableDrawer}>
                <AddCircleOutlineIcon />
              </Button>

            </div>
          </Typography>

          <Divider />

          <List
            {...props}
            exporter="false"
            filters={postFilters}
            style={{ maxHeight: '500px', overflow: 'auto' }}>

            {adminDetails && adminDetails.map(adminrec => (
              <>
                <ListItem
                  button
                  key={adminrec.id}
                  alignItems="flex"
                >
                  < ListItemText
                    primary={`${adminrec.name}`}
                    secondary={
                      <Typography
                        component="span"
                        variant="inherit"
                        className={classes.inline}
                        color="textSecondary"
                        align="flex-start"
                      >

                        <span>{`\n${adminrec.city} \t ${adminrec.country}.\n`}</span>
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction >

                    <IconButton edge="end" aria-label="edit"
                      onClick={() => handleClickOpen(adminrec.name, adminrec.city, adminrec.country, adminrec.address)}>

                      <PersonAddIcon />
                      <span className="ml-3 p-1" style={{ fontSize: 'smaller', borderRadius: '50%' }}>{adminrec.corporateMail.length - 1} </span>

                    </IconButton>

                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem
                  button
                  key={adminrec.id}
                  alignItems="flex"
                  onClick={() => handleClick(adminrec.id)}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textSecondary"
                        >

                          {`${adminrec.address}\n`}

                        </Typography>


                      </React.Fragment>
                    }
                  />
                  {open === adminrec.id ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={open === adminrec.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {adminrec.corporateMail && adminrec.corporateMail.map(mail => (

                      <>
                        {
                          mail && mail != state.sessionMail ?
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <PersonIcon />
                              </ListItemIcon>
                              <ListItemText primary={`\n${mail}`} />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="remove"
                                  onClick={() => removeAdmin(mail, adminrec.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem> : <></>
                        }
                      </>
                    ))}
                  </List>
                </Collapse>
                <Divider />
              </>
            ))}

          </List>


          <Drawer
            variant="persistent"
            open={state.isDrawerOpen}
            anchor="right"
            onClose={handleCloseDrawer}>

            <CreateLocation sessionMailArray={state.sessionMailArray} userList={organizationUsers} organizationid={userorganizationidd} onCancel={handleCloseDrawer} {...props} />

          </Drawer>

        </Card>

        <SimpleDialog data={state} sessionMail={state.sessionMail} userList={organizationUsers} selectedValue={selectedValue} open={openDialoue} onClose={onSelectDialogue} />

      </div>

    </Fragment>


};


//open dialogue box to choose admin from list
const SimpleDialog = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open, userList, sessionMail, data } = props;
  const notify = useNotify();
  const history = useHistory()
  const refresh = useRefresh()


  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = async (value) => {

    if (value && value.email && value.email != '' && value.email != null) {
      await addAdminToExistingLocation(value.email, value.user_id)
    }
    onClose(value);
  };

  async function addAdminToExistingLocation(adminmail, user_id) {


    const requestOptions = {
      method: 'POST',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': data.token
      },
      data: JSON.stringify({

        address: data.address,
        city: data.city,
        name: data.name,
        country: data.country,
        adminmail: adminmail,
        user_id: user_id

      })

    };

    return axios(`${NODE_API}/organization/location/admin/${userorganizationidd}`, requestOptions)

      .then(async res => {

        if (res && res.status === 208) {
          notify(`Something went wrong try again later`, { type: 'warning' })
          refresh();

        }
        else if (res && res.status === 200) {
          notify(`Admin added successfully`, { type: 'success' })
          refresh();

        } else if (res && res.status === 204) {
          notify(`Admin already exists`, { type: 'warning' })
        }
        else {
          notify(`Something went wrong try again later`, { type: 'warning' })
          refresh();

        }

        return res;
      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })
        history.push('/organization/location/info')
      });
  }

  const items = [{
    "id": 1,
    "attributes": {
      "Course ": "English",
      "Workhours": 80
    },
    "groupId": 1,
    "name": "1",
    "uniqueId": "1236547",
    "status": "still working"
  }]

  return (
    <Dialog onClose={handleClose} className='w-100' aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Admin</DialogTitle>

      <List
        className="m-2 p-2"
        style={{ maxHeight: '800px', width: '500px', overflow: 'auto' }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">

          </ListSubheader>
        }>


        {userList && userList.length > 0 ?
          <>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={userList.map((option) => option.email)}
              renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search by Email"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
              )}
            />
            {userList && userList.map((email) => (
              <>
                {email && email.email == sessionMail ?
                  <>
                    <ListItem
                      button
                      key={email.id}
                      alignItems="flex"
                      onClick={() => handleListItemClick(email)}
                    >
                      <ListItemText
                        primary={`${email.empName}`}
                        secondary={
                          <Typography
                            component="span"
                            variant="inherit"
                            color="textSecondary"

                          >
                            <div>{`${email.designation}`}</div>
                            <div>{`${email.email}`}</div>

                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </> :

                  <>
                    <ListItem
                      button
                      key={email.id}
                      alignItems="flex"
                      onClick={() => handleListItemClick(email)}
                    >
                      <ListItemText
                        primary={`${email.empName}`}
                        secondary={
                          <Typography
                            component="span"
                            variant="inherit"
                            color="textSecondary"

                          >
                            <div>{`${email.designation}`}</div>
                            <div>{`${email.email}`}</div>

                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </>
                }
              </>
            ))}
          </>
          : <h6 className="p-2 text-danger m-2">
            *No registered employee to add as a admin </h6>
        }
      </List>


    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};




export default LocationList;