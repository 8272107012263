import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ThumbUp from '@material-ui/icons/ThumbUp';
import {
    Button,
    useUpdateMany,
    useNotify,
    useRedirect,
    useUnselectAll,
    CRUD_UPDATE_MANY,
    useDataProvider,
    useVersion,
} from 'react-admin';

const BulkAcceptButton = ({ selectedIds }) => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const unselectAll = useUnselectAll('request');

    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();

    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        role && role === 'LOCATION_ADMIN' ? path = 'organization/location/users/cards/request' :
            (role && role === 'CORPORATE_ADMIN' ? path = 'organization/users/cards/request' : path = '/login')


        setState(state => ({

            ...state,

            path: path

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserRole()
    }, [version])

    const [approve, { loading }] = useUpdateMany(
        `${state.path}`,
        selectedIds,
        { is_approved: 'T' },
        {
            action: CRUD_UPDATE_MANY,
            undoable: true,
            onSuccess: () => {
                notify(
                    'Request Approved',
                    'info',
                    {},
                    true
                );
                redirectTo(`/${state.path}`);
                unselectAll();
            },
            onFailure: () => {
                notify(
                    'Request Approved Error',
                    'warning'
                );
            },
        }
    );

    return (
        <Button
            label="accept"
            onClick={approve}
            disabled={loading}>
            <ThumbUp />
        </Button>
    );
};

BulkAcceptButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkAcceptButton;
