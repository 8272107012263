import React, { Fragment, useCallback, useEffect, useState } from 'react';
import StyledTabbedDatagrid from './TabbedDataGrid';
import classnames from 'classnames';
import { Route, useHistory } from 'react-router-dom';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import { Filter, List, SearchInput, useDataProvider, useRedirect, useVersion } from 'react-admin';
import BulkRemoveUsrButton from './BulkRemoveUsrButton';

const EmpFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" resettable alwaysOn />

    </Filter>
);

const EmpBulkActionButtons = (props) => {


    return (
        <Fragment>

            <BulkRemoveUsrButton {...props} />
        </Fragment>
    )
};


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
        textAlign: 'center'
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        width: '600px',
        zIndex: 100,
    },
}));

const EmployeeList = props => {

    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [state, setState] = useState({});
    const version = useVersion();
    const redirect = useRedirect();


    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        if (role && role === 'LOCATION_ADMIN') {
            setState(state => ({

                ...state,

                role: role
            }));
        } else if (role && role === 'CORPORATE_ADMIN') {
            setState(state => ({

                ...state,

                role: role
            }));
        } else {
            redirect('/login')
        }

    }, [])


    useEffect(() => {
        checkUserRole()
    }, [version])




    return (
        <>
            {state && state.role == 'CORPORATE_ADMIN' ?
                <div className={classes.root}>
                    <Route path="/organization/users/list:id">
                        {({ match }) => {
                            const isMatch = !!(
                                match &&
                                match.params &&
                                match.params.id !== 'create'
                            );

                            return (
                                <Fragment>
                                    <List

                                        {...props}
                                        className={classnames(classes.list, {
                                            [classes.listWithDrawer]: isMatch,
                                        })}
                                        filterDefaultValues={{ is_quit: "F" }}
                                        bulkActionButtons={<EmpBulkActionButtons props={props} />}
                                        filters={<EmpFilter />}

                                        perPage={10}
                                        sort={{ field: 'name', order: 'ASC' }}
                                        pagination={false}
                                    >
                                        {isXSmall ? (
                                            <StyledTabbedDatagrid />
                                        ) : (
                                            <StyledTabbedDatagrid />
                                        )}
                                    </List>

                                </Fragment>
                            );
                        }}
                    </Route>
                </div>
                :

                <div className={classes.root}>
                    <Route path="/organization/location/users/list">
                        {({ match }) => {
                            const isMatch = !!(
                                match &&
                                match.params &&
                                match.params.id !== 'create'
                            );

                            return (
                                <Fragment>
                                    <List
                                        {...props}
                                        className={classnames(classes.list, {
                                            [classes.listWithDrawer]: isMatch,
                                        })}
                                        filterDefaultValues={{ is_quit: "F" }}
                                        bulkActionButtons={<EmpBulkActionButtons />}
                                        filters={<EmpFilter />}
                                        perPage={10}
                                        sort={{ field: 'name', order: 'ASC' }}
                                        pagination={false}
                                    >
                                        {isXSmall ? (
                                            <StyledTabbedDatagrid />
                                        ) : (
                                            <StyledTabbedDatagrid />
                                        )}
                                    </List>

                                </Fragment>
                            );
                        }}
                    </Route>
                </div>
            }
        </>

    );
};

export default EmployeeList;
