import React, { useEffect } from 'react';
import AdminPanel from './adminPanel';
import './App.css';
import SharedRoute from './projectRoutes/SharedRoute';

function App() {

    useEffect(() => {

        if (isLoggedIn) {
            document.body.style.zoom = "80%";
        } else {
            document.body.style.zoom = "100%";
        }
    }, [])

    const isLoggedIn = sessionStorage.getItem('user')

    const LoggedInDiv = <> <SharedRoute /> </>

    const LoggedOutDiv = <> <AdminPanel /> </>

    const renderLoggedInState = () => {

        if (isLoggedIn) {
            return LoggedOutDiv;
        } else {
            return LoggedInDiv
        }
    }

    return (
        <>
            {renderLoggedInState()}
        </>
    );
}

export default App;
