import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import SubMenu from './SubMenu';
import BusinessIcon from '@material-ui/icons/Business';
import ContactsIcon from '@material-ui/icons/Contacts';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ListIcon from '@material-ui/icons/List';
import WallpaperIcon from '@material-ui/icons//Wallpaper';

const SuperAdminMenu = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    });
    const translate = useTranslate();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.theme);
    const handleToggle = menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} style={{ marginTop: "2em" }} />

            <SubMenu
                handleToggle={() => handleToggle('menuOrganization')}
                isOpen={state.menuOrganization}
                sidebarIsOpen={open}
                name="Organization"
                icon={<BusinessIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/createorganization`}
                    primaryText={translate(`Create organization`)}
                    leftIcon={<NoteAddIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/createadmin`}
                    primaryText={translate(`Create admin`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ContactsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />

                <MenuItemLink
                    to={`/organization/lists`}
                    primaryText={translate(`List organization`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={`/templates`}
                    primaryText={translate(`Card templates`, {
                        smart_count: 2,
                    })}
                    leftIcon={<WallpaperIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>


            {isXsmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText="change password"
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXsmall && logout}
        </div>
    );
};

SuperAdminMenu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default SuperAdminMenu;
