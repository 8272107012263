export function validateField(fieldName, value, state, status) {
    let fieldValidationErrors = state.formErrors;
    let emailValid = state.emailValid;
    let usernameValid = state.usernameValid;
    let passwordValid = state.passwordValid;
    let confirmPasswordValid = state.confirmPasswordValid;


    switch (fieldName) {
        case 'username':

            usernameValid = value.match(/^[A-Za-z][A-Za-z0-9_\s]{4,29}$/i);

            fieldValidationErrors.username = usernameValid ? '' : 'Enter valid username';
            break;

        case 'email':

            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
            break;

        case 'password':

            if (status) {
                if (status === value) {
                    passwordValid = true
                } else {
                    passwordValid = false
                }
            }
            fieldValidationErrors.password = passwordValid ? '' : ' The two passwords you entered do not match';
            break;

        case 'confirmPassword':

            if (status) {
                if (status === value) {
                    confirmPasswordValid = true
                } else {
                    confirmPasswordValid = false
                }
            }

            fieldValidationErrors.confirmPassword = confirmPasswordValid ? '' : ' The two passwords you entered do not match';

            break;

        default:
            break;
    }

    return ({
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        usernameValid: usernameValid,
        confirmPasswordValid: confirmPasswordValid
    });
}

export function errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
}