import { history } from './helpers/history'

export default {

    logout: () => {
        sessionStorage.removeItem('user');
        history.push('/login')
        
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        sessionStorage.getItem('user') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject('Unknown method'),
};
