import React, { useState, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListContext,
    useDataProvider, useVersion, useNotify
} from 'react-admin';
import '../card.css'
import { NODE_API } from '../../../../../config';
import axios from 'axios';
import { useMediaQuery } from '@material-ui/core';
import { flipInY } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
const useStyles = makeStyles({
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    name: { display: "inline-block", marginLeft: '1em' },
    displayblock: { display: "inline-block" }
});
const styles = {
    flipInY: {
        animation: 's 2s',
        animationName: Radium.keyframes(flipInY, 'flipInY')
    }
}

//local use values
var firstName = "", lastName = "", title = "", email = "", contact = "", address = "";

const FrontView = ({ change }) => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {

        fetchcards()
        generateQRCode()

    })

    //generate contact scanner
    function generateQRCode() {

        var qrImage = document.getElementById("qrImage")
        var startNode = "BEGIN:VCARD" + "\n" + "VERSION:3.0" + "\n";
        var endNode = "END:VCARD";
        startNode += "N:" + firstName.trim() + "\n";
        startNode += "FN:" + lastName.trim() + "\n";
        startNode += "EMAIL:" + email.trim() + "\n";
        startNode += "TYPE:" + "personal" + "\n";
        startNode += "TITLE:" + title.trim() + "\n";
        startNode += "TEL:" + contact.trim() + "\n";
        startNode += "ADR:" + address.trim() + "\n";
        startNode += endNode;
        qrImage.style.display = 'none';
        // qrImage.src = "https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=200x200&chl="
        //     + encodeURIComponent(startNode);
        qrImage.src = `https://qrcode.tec-it.com/API/QRCode?data=${encodeURIComponent(startNode)}&scalex=1&scaley=1&format=jpg&dpi=96`;

        qrImage.style.display = 'inline';

    }
    const setDesign = (design) => {
        if (design) {
            design.map(function (element) {


                if (element.isDisable != true) {

                    var topvalue = element.position.top + "px"
                    document.getElementById(element.position.name).style.top = topvalue;
                    var leftvalue = element.position.left + "px"
                    document.getElementById(element.position.name).style.left = leftvalue;
                    if (element.name === "mydivvl") {
                        document.getElementById("mydivvl").style.width = element.position.width;
                        document.getElementById("mydivvl").style.backgroundColor = element.position.backgroundColor;
                        document.getElementById(element.position.name).style.cursor = "default";
                    }
                    else if (element.name === "mydivhl") {
                        document.getElementById("mydivhl").style.height = element.position.height
                        document.getElementById("mydivhl").style.borderColor = element.position.borderColor;
                        document.getElementById(element.position.name).style.cursor = "default";
                    }
                    else if (element.name != "logo") {
                        document.getElementById(element.name).innerHTML = element.value
                        document.getElementById(element.name).style.fontFamily = element.textStyle;
                        document.getElementById(element.name).style.textDecoration = element.fontStyle.underline;
                        document.getElementById(element.name).style.fontStyle = element.fontStyle.italic;
                        document.getElementById(element.name).style.fontWeight = element.fontStyle.bold;
                        document.getElementById(element.name).style.fontSize = element.fontSize
                        document.getElementById(element.name).style.color = element.fontColor
                        document.getElementById(element.position.name).style.cursor = "default";
                        if (element.name === "nameset") {

                            var value = element.value
                            var convertvalue = value.split(" ");
                            if (convertvalue.length === 2) {

                                firstName = convertvalue[0]
                                lastName = convertvalue[1]

                            }
                            else {

                                firstName = element.value

                            }
                        }
                        if (element.name === "sloganset") {

                            title = element.value

                        }
                        if (element.name === "contactset") {

                            contact = element.value

                        } if (element.name === "emailset") {

                            email = element.value

                        } if (element.name === "addressset") {

                            address = element.value

                        }

                    }

                    else {
                        if (element.backgroundtemplate != null)
                            document.getElementById("card").style.backgroundImage = `url(${element.backgroundtemplate})`;
                        if (element.value != null)
                            document.getElementById("mydivlogo").style.backgroundImage = `url(${element.value})`;
                        document.getElementById("mydivlogo").style.width = element.position.width;
                        document.getElementById("mydivlogo").style.height = element.position.height
                        document.getElementById(element.position.name).style.border = "none"
                        document.getElementById("logo").style.border = "none"
                    }

                } else {

                    if (element.name === "logo") {
                        document.getElementById("mydivlogo").style.visibility = "hidden";
                        document.getElementById("card").style.backgroundImage = `url(${element.backgroundtemplate})`;
                    }

                    else if (element.name === "vlset") {
                        document.getElementById("mydivvl").style.visibility = "hidden";

                    }
                    else if (element.name === "hlset") {
                        document.getElementById("mydivhl").style.visibility = "hidden";

                    }
                    else {
                        document.getElementById(element.name).style.visibility = "hidden";

                    }

                }
            })

        }

        return;
    }
    //Get list of organizations
    const fetchcards = useCallback(async () => {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'GET',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }

        };

        // requestOptions
        await axios(`${NODE_API}/card/personal`, requestOptions)

            .then(res => {
                document.getElementById("requesta").style.display = "none";

                if (res && res.data[0]) {
                    setDesign(res.data[0].front)
                    generateQRCode()
                }
                else {
                    document.getElementById("sharedivqr").style.display = "none";
                    document.getElementById("card").style.display = "none";
                    document.getElementById("requesta").style.display = "block";

                    return;

                }
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });

    }, [dataProvider])

    return (
        isSmall && isXSmall ?

            <>
                <div className="main">

                    <div className="setting">

                        <div className="cardMobile" id="card" style={{ marginTop: "1em", marginLeft: '1em' }}>
                            <span id="mydivlogo" className="mydivlogo" style={{ filter: "drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white)" }}>
                                <div className="logo" id="logo"></div>
                            </span>
                            <span id="mydivname">
                                <p className="name" id="nameset"  ></p>
                            </span>
                            <span id="mydivslogan">
                                <p className="slogan" id="sloganset"></p>
                            </span>
                            <span id="mydivcontact" >
                                <p className="contact" id="contactset"></p>
                            </span>
                            <span id="mydivaddress">
                                <p className="address" id="addressset"></p>
                            </span>
                            <span id="mydivemail" >
                                <p className="email" id="emailset"></p>
                            </span>
                            <span id="mydivhl" >
                                <hr className="hl" id="hlset" />

                            </span>
                            <span id="mydivvl" >
                                <p className="vl" id="vlset"></p>

                            </span>

                        </div>

                    </div>

                </div>

                <div className='setting ' id ="sharedivqr" style={{ marginTop: "2em" }} >
                    <img id='qrImage' style={{ display: 'inline' }}
                        src='https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=200x200&chl=http%3A%2F%2sovica.in' />

                </div>
                <h6 id="requesta" style={{ display: 'inline', width: '100%', marginTop: '2%', color: 'gray' }}>oops !!! It's looks like you didn't design card yet.</h6>

                <a href="#" id="link" download="visiting card.png"></a>
            </>
            :
            <>

                <div className="main">
                    <div className="setting">
                        <StyleRoot style={{ marginTop: "2em" }}>
                            <div className="card" id="card" style={{ marginTop: "1em" }} onClick={change} style={styles.flipInY}>
                                <span id="mydivlogo" className="mydivlogo" style={{ filter: "drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white)" }}>
                                    <div className="logo" id="logo"></div>
                                </span>
                                <span id="mydivname">
                                    <p className="name" id="nameset"  ></p>
                                </span>
                                <span id="mydivslogan">
                                    <p className="slogan" id="sloganset"></p>
                                </span>
                                <span id="mydivcontact" >
                                    <p className="contact" id="contactset"></p>
                                </span>
                                <span id="mydivaddress">
                                    <p className="address" id="addressset"></p>
                                </span>
                                <span id="mydivemail" >
                                    <p className="email" id="emailset"></p>
                                </span>
                                <span id="mydivhl" >
                                    <hr className="hl" id="hlset" />
                                </span>
                                <span id="mydivvl" >
                                    <p className="vl" id="vlset"></p>
                                </span>
                            </div>
                        </StyleRoot>
                    </div>
                    <div className='setting ' style={{ marginTop: "5em" }} id ="sharedivqr" >
                        <img id='qrImage' style={{ display: 'inline' }}
                            src='https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=200x200&chl=http%3A%2F%2sovica.in' />
                    </div>
                </div>
                <h6 id="requesta" style={{ display: 'inline', width: '100%', marginTop: '2%', color: 'gray' }}>oops !!! It's looks like you didn't design card yet.</h6>

                <a href="#" id="link" download="visiting card.png"></a>

            </>
    );
};
const BackView = ({ change }) => {

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchcards()
    })

    const setDesign = (design) => {
        if (design) {
            design.map(function (element) {
                if (element.isDisable != true) {
                    var topvalue = element.position.top + "px"
                    document.getElementById(element.position.name).style.top = topvalue;
                    var leftvalue = element.position.left + "px"
                    document.getElementById(element.position.name).style.left = leftvalue;
                    if (element.name === "mydivvl") {
                        document.getElementById("mydivvl").style.width = element.position.width;
                        document.getElementById("mydivvl").style.backgroundColor = element.position.backgroundColor;
                        document.getElementById(element.position.name).style.cursor = "default";
                    }
                    else if (element.name === "mydivhl") {
                        document.getElementById("mydivhl").style.height = element.position.height
                        document.getElementById("mydivhl").style.borderColor = element.position.borderColor;
                        document.getElementById(element.position.name).style.cursor = "default";
                    }
                    else if (element.name != "logo") {
                        document.getElementById(element.name).innerHTML = element.value
                        document.getElementById(element.name).style.fontFamily = element.textStyle;
                        document.getElementById(element.name).style.textDecoration = element.fontStyle.underline;
                        document.getElementById(element.name).style.fontStyle = element.fontStyle.italic;
                        document.getElementById(element.name).style.fontWeight = element.fontStyle.bold;
                        document.getElementById(element.name).style.fontSize = element.fontSize
                        document.getElementById(element.name).style.color = element.fontColor
                        document.getElementById(element.position.name).style.cursor = "default";
                        if (element.name === "nameset") {
                            var value = element.value
                            var convertvalue = value.split(" ");
                            if (convertvalue.length === 2) {

                                firstName = convertvalue[0]
                                lastName = convertvalue[1]

                            }
                            else {

                                firstName = element.value

                            }
                        }
                        if (element.name === "sloganset") {

                            title = element.value

                        }
                        if (element.name === "contactset") {

                            contact = element.value

                        } if (element.name === "emailset") {

                            email = element.value

                        } if (element.name === "addressset") {

                            address = element.value

                        }

                    }

                    else {
                        if (element.backgroundtemplate != null)
                            document.getElementById("card").style.backgroundImage = `url(${element.backgroundtemplate})`;

                        if (element.value != null)
                            document.getElementById("mydivlogo").style.backgroundImage = `url(${element.value})`;
                        document.getElementById("mydivlogo").style.width = element.position.width;
                        document.getElementById("mydivlogo").style.height = element.position.height
                        document.getElementById(element.position.name).style.border = "none"
                        document.getElementById("logo").style.cursor = "default";

                    }

                } else {
                    if (element.name === "logo") {
                        document.getElementById("mydivlogo").style.visibility = "hidden";
                        document.getElementById("card").style.backgroundImage = `url(${element.backgroundtemplate})`;

                    }

                    else if (element.name === "sloganset") {
                        document.getElementById(element.name).style.visibility = "hidden";



                    } else if (element.name === "nameset") {
                        document.getElementById(element.name).style.visibility = "hidden";

                    }
                    else if (element.name === "vlset") {
                        document.getElementById("mydivvl").style.visibility = "hidden";

                    }
                    else if (element.name === "hlset") {
                        document.getElementById("mydivhl").style.visibility = "hidden";

                    }
                    else {
                        document.getElementById(element.name).style.visibility = "hidden";
                    }

                }
            })

        }
        return;
    }
    //Get list of organizations
    const fetchcards = useCallback(async () => {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let token;
        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }
        const requestOptions = {
            method: 'GET',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };
        // requestOptions
        await axios(`${NODE_API}/card/personal`, requestOptions)

            .then(res => {
                if (res && res.data[0])
                    setDesign(res.data[0].back)
                else
                    return;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });

    }, [dataProvider])


    return (
        isSmall && isXSmall ?
            <>
                <div className="main">

                    <div className="setting">

                        <div className="cardMobile" id="card" style={{ marginTop: "1em", marginLeft: '1em' }}>
                            <span id="mydivlogo" className="mydivlogo" style={{ filter: "drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white)" }}>
                                <div className="logo" id="logo"></div>
                            </span>
                            <span id="mydivname">
                                <p className="name" id="nameset"  ></p>
                            </span>
                            <span id="mydivslogan">
                                <p className="slogan" id="sloganset"></p>
                            </span>
                            <span id="mydivcontact" >
                                <p className="contact" id="contactset"></p>
                            </span>
                            <span id="mydivaddress">
                                <p className="address" id="addressset"></p>
                            </span>
                            <span id="mydivemail" >
                                <p className="email" id="emailset"></p>
                            </span>
                            <span id="mydivhl" >
                                <hr className="hl" id="hlset" />

                            </span>
                            <span id="mydivvl" >
                                <p className="vl" id="vlset"></p>

                            </span>

                        </div>

                    </div>

                </div>

                <div className='setting ' style={{ marginTop: "2em" }} >
                    <img id='qrImage' style={{ display: 'inline' }}
                        src='https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=200x200&chl=http%3A%2F%2sovica.in' />
                </div>
                <a href="#" id="link" download="visiting card.png"></a>
            </>
            :
            <>
                <div className="main" >
                    <div className="setting">
                        <StyleRoot style={{ marginTop: "2em" }}>
                            <div className="card" id="card" style={{ marginTop: "1em" }} onClick={change} style={styles.flipInY}>
                                <span id="mydivlogo" className="mydivlogo" style={{ filter: "drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white)" }}>
                                    <div className="logo" id="logo"></div>
                                </span>
                                <span id="mydivname">
                                    <p className="name" id="nameset"  ></p>
                                </span>
                                <span id="mydivslogan">
                                    <p className="slogan" id="sloganset"></p>
                                </span>

                                <span id="mydivhl" >
                                    <hr className="hl" id="hlset" />

                                </span>
                                <span id="mydivvl" >
                                    <p className="vl" id="vlset"></p>

                                </span>

                            </div>

                        </StyleRoot>


                    </div>
                </div>
                <a href="#" id="link" download="visiting card.png"></a>
            </>
    );
};


const ViewCard = (props) => {
    const [state, setState] = React.useState({

        view: 0
    });
    const change = () => {
        if (state.view == 0) {
            setState(state => ({

                ...state,

                view: 1

            }))
        }
        else {
            setState(state => ({

                ...state,

                view: 0

            }))
        }

    }
    return (
        <>

            {
                state.view === 0 ?
                    <FrontView change={change} />
                    :
                    <BackView change={change} />
            }

        </>
    )
};
export default ViewCard;

