import React, { useState } from "react";
import { PasswordInput, useRedirect } from "react-admin";
import {
  SimpleForm,
  required,
  useNotify,
  Confirm,
  useRecordContext,
  useDelete,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { Button } from "@material-ui/core";
import axios from "axios";
import { Redirect } from "react-router";
import { NODE_API } from "../config";
import DeleteIcon from "@material-ui/icons/Delete";
import { history } from '../helpers/history';

var CryptoJS = require("crypto-js");

const validatePassword = [required()];

const Settings = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);


  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    setOpen(false);
    await deleteAccount();
  };

  async function deleteAccount(password) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }


    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      //   data: JSON.stringify({
      //     password: encryptedLoginPassword
      //   })
    };

    return axios(`${NODE_API}/user/account/status`, requestOptions)
      .then(async (res) => {
        if (res && res.status === 200) {
          notify(`Account deleted succesfully`, { type: "success" });
          sessionStorage.removeItem('user');
          history.push('/login')
          // redirect('/login')
        }

        return res;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
        Redirect("/");
      });
  }

;

  return (
    <>
      <Button
        variant="outlined"
        color="error" 
        fullWidth={false}
        style={{maxWidth:'50%', margin:20,backgroundColor:'#DC3545',color:'#fff'}}
        startIcon={<DeleteIcon />}
        onClick={handleClick}
      >
        Delete Account
      </Button>
      <Confirm
        isOpen={open}
        title={`Delete Account`}
        content="Are you sure you want to delete your account?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default Settings;
