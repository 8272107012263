import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/Business';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useVersion, useDataProvider, useTranslate, useQueryWithStore } from 'react-admin';
import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const NewOrganizations = () => {
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const featchOrganizations = useCallback(async () => {
        var sendData = [];
        await dataProvider.getList(
            'organization/list',
            {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'registration_date', order: 'DESC' }
            }
        )
            .then(res => {

                res.data.map(function (element) {
                    sendData.push({ "label": element.label, "value": element.value, "registration_date": element.registration_date, "img": element.img })
                })


            })
            .catch(e => console.log('error : ' + e));
        ;

        setState(state => ({

            ...state,


            organizationDetails: sendData,


        }));
    }, [dataProvider])

    useEffect(() => {
        featchOrganizations()
    }, [version])

    const { organizationDetails } = state
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    New organizations registered in last 7 days
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {!organizationDetails ? 0 : organizationDetails.length}
                </Typography>
                <Divider />
                {!organizationDetails ? <List>
                    <ListItem>
                        <ListItemText
                            primary={`Organization not registered`}
                        />
                    </ListItem>
                </List> :
                    <List style={{ maxHeight: '500px', overflow: 'auto' }}>
                        {organizationDetails.map(record => (
                            <ListItem
                                button
                                key={record.id}
                            >
                                {/* <ListItemAvatar style={{objectFit:"contain"}}>
                                    <Avatar src={`${record.img}`} />
                                </ListItemAvatar> */}
                                <ListItemText
                                    primary={`${record.label} `}
                                    secondary={`${record.registration_date}`}
                                />
                            </ListItem>
                        ))}
                    </List>}
            </Card>
        </div>
    );
};

export default NewOrganizations;
