import { encryptOTPKey } from '../config'
import CryptoJS from 'crypto-js';

export function passwordEncryption(password) {

}

export function OTPGenerator() {

    var randomOTP = Math.floor((Math.random() * 10000) + 1000);
    return randomOTP;

}

export function OTPEncryption(OTP) {

    var OTPString = String(OTP)

    var encryptData = CryptoJS.AES.encrypt(OTPString, encryptOTPKey);

    return encryptData;


}