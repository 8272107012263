import { userConstants, mailConstants } from '../../../constants/constants';

import { userService } from '../../../services/user.service';
import { errorAlert, successAlert } from './alert';
import { history } from '../../../helpers/history';

export function login(username, password) {
    return dispatch => {
        dispatch(request({ email: username }));

        userService.login(username, password)
            .then(
                user => {

                    dispatch(success(user));

                    dispatch(successAlert(user.toString()));

                    history.push('/dashboard');

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

export function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

export function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    dispatch(successAlert('Registration successful'));
                    history.push('/login');

                },
                error => {
                    if (error.message.includes(401)) {
                        dispatch(errorAlert('Email Already Exists'));
                        // history.push('/sign-up');

                    } else {
                        dispatch(errorAlert('Something went wrong'));
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}


export function sendOTPMail(mail, otp) {
    return dispatch => {
        dispatch(request(mail));


        userService.sendOTPMail(mail, otp)
            .then(
                res => {
                    dispatch(success());
                    dispatch(successAlert('Check Your Mail for OTP Verification'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert('Something went wrong'));

                }
            );
    };

    function request(response) { return { type: mailConstants.OTP_MAIL_REQUEST, response } }
    function success(response) { return { type: mailConstants.OTP_MAIL_SUCCESS, response } }
    function failure(error) { return { type: mailConstants.OTP_MAIL_FAILURE, error } }
}

export function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
export function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}