import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRedirect, useDataProvider, useVersion } from 'react-admin';
/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record, frontImageURI, backImageURI }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();
    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        role && role === 'LOCATION_ADMIN' ? path = 'organization/location/users/cards/request' :
            (role && role === 'CORPORATE_ADMIN' ? path = 'organization/users/cards/request' : path = '/login')


        setState(state => ({

            ...state,

            path: path

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserRole()
    }, [version])


    const [approve, { loading }] = useUpdate(
        `${state.path}`,
        record.id,
        {
            is_approved: 'T',
            imgFront: frontImageURI,
            imgBack: backImageURI
        },
        record,
        {
            undoable: true,

            onSuccess: () => {

                notify(
                    'Request is accepted',
                    'info',
                    {},
                    true
                );

                redirectTo(`/${state.path}`);
            },
            onFailure: (e) => {
                console.log('checking error : ' + e)
                notify(
                    'Request accept error',
                    'warning'
                );
            },
        }
    );
    return record && record.is_approved === 'P' ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={approve}
            disabled={loading}>

            <ThumbUp
                color="primary"
                style={{ paddingRight: '0.5em', color: 'green' }}
            />
            {translate('accept')}
        </Button>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.object,
};

export default AcceptButton;
