import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import LabelIcon from "@material-ui/icons/Label";
import { useMediaQuery } from "@material-ui/core";
import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import RecentActorsRoundedIcon from "@material-ui/icons/RecentActorsRounded";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import EditLocation from "@material-ui/icons/LocationCity";
import EditAttributes from "@material-ui/icons/EditOutlined";
import VisitorIcon from "@material-ui/icons/People";
import { useTranslate, DashboardMenuItem, MenuItemLink } from "react-admin";
import SubMenu from "./SubMenu";
import { AddLocation } from "@material-ui/icons";

const CorporateAdminMenu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
    menuCards: false,
    menuCardsPersonal: false,
    menuCardsCorporate: false,
    Organization: false,
  });
  const translate = useTranslate();
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      {" "}
      <DashboardMenuItem
        onClick={onMenuClick}
        sidebarIsOpen={open}
        style={{ marginTop: "2em" }}
      />
      <SubMenu
        handleToggle={() => handleToggle("Organization")}
        isOpen={state.Organization}
        sidebarIsOpen={open}
        name="Organization"
        icon={<BusinessCenter />}
        dense={dense}
      >
        <MenuItemLink
          to={`/edit-organization`}
          primaryText={translate(`Edit Organization`, {
            smart_count: 2,
          })}
          leftIcon={<EditAttributes />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/organization/location/info`}
          primaryText={translate(`Add Location`, {
            smart_count: 2,
          })}
          leftIcon={<AddLocation />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="Users"
        icon={<VisitorIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/organization/users/cards/request`}
          primaryText={translate(`Requests`, {
            smart_count: 2,
          })}
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/organization/users/list`}
          primaryText={translate(`List`, {
            smart_count: 2,
          })}
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCards")}
        isOpen={state.menuCards}
        sidebarIsOpen={open}
        name="Cards"
        icon={<RecentActorsRoundedIcon />}
        dense={dense}
      >
        <SubMenu
          handleToggle={() => handleToggle("menuCardsPersonal")}
          isOpen={state.menuCardsPersonal}
          sidebarIsOpen={open}
          name="Personal"
          icon={<RecentActorsRoundedIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/personal-card-view`}
            primaryText={translate(`View Card`, {
              smart_count: 2,
            })}
            leftIcon={<RecentActorsOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/personal-card-design`}
            primaryText={translate(`Design Card`, {
              smart_count: 2,
            })}
            leftIcon={<RecentActorsOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle("menuCardsCorporate")}
          isOpen={state.menuCardsCorporate}
          sidebarIsOpen={open}
          name="Corporate"
          icon={<RecentActorsRoundedIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/corporate-card-view`}
            primaryText={translate(`View Card`, {
              smart_count: 2,
            })}
            leftIcon={<RecentActorsOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/card-design`}
            primaryText={translate(`Card Request`, {
              smart_count: 2,
            })}
            leftIcon={<RecentActorsOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/corporate-admin-card-design`}
            primaryText={translate(`Card Design`, {
              smart_count: 2,
            })}
            leftIcon={<RecentActorsOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      </SubMenu>

      <MenuItemLink
        to={`/settings`}
        primaryText={translate(`Settings`, {
          smart_count: 2,
        })}
        leftIcon={<SettingsIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      {isXsmall && (
            <>
            <MenuItemLink
            to="/configuration"
            primaryText="change password"
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            />
            <MenuItemLink
            to={`/settings`}
            primaryText={translate(`Settings`, {
            smart_count: 2,
            })}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
        />
        </>
      )}
      {isXsmall && logout}
    </div>
  );
};

CorporateAdminMenu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};

export default CorporateAdminMenu;
