import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { useMediaQuery } from '@material-ui/core';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import LabelIcon from '@material-ui/icons/Label';
import { useTranslate, DashboardMenuItem, MenuItemLink, useVersion, useDataProvider } from 'react-admin';
import SubMenu from './SubMenu';

const UserMenu = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCustomers: false,
        menuCards: false,
        menuCardsPersonal: false,
        menuCardsCorporate: false,
        Organization: false,
        menuOrganization: false

    });
    const version = useVersion();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.theme); // force rerender on theme change

    const handleToggle = menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };


    const checkUserType = useCallback(async () => {


        let user = JSON.parse(sessionStorage.getItem('user'));
        let type;

        if (user && user.type) {
            type = user.type
        } else {
            type = {}
        }

        setState(state => ({

            ...state,

            userType: type

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserType()
    }, [version])

    const { userType } = state
    return (
        <div>
            {userType && userType === 'LOCATION_ADMIN' ?
                <>
                    {/* Sidebar menu if user type is LOCATION_ADMIN */}
                    <DashboardMenuItem
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ marginTop: "2em" }} />

                    <SubMenu
                        handleToggle={() => handleToggle('menuOrganization')}
                        isOpen={state.menuOrganization}
                        sidebarIsOpen={open}
                        name="Organization"
                        icon={<BusinessCenter />}
                        dense={dense}>

                        <MenuItemLink
                            to={`/organization/location/users/cards/request`}
                            primaryText={translate(`Requests`, {
                                smart_count: 2,
                            })}
                            leftIcon={<LabelIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense} />

                    </SubMenu>

                    <SubMenu
                        handleToggle={() => handleToggle('menuCards')}
                        isOpen={state.menuCards}
                        sidebarIsOpen={open}
                        name="Cards"
                        icon={<RecentActorsRoundedIcon />}
                        dense={dense}>

                        <SubMenu
                            handleToggle={() => handleToggle('menuCardsPersonal')}
                            isOpen={state.menuCardsPersonal}
                            sidebarIsOpen={open}
                            name="Personal"
                            icon={<RecentActorsRoundedIcon />}
                            dense={dense}>

                            <MenuItemLink
                                to={`/personal-card-view`}
                                primaryText={translate(`View Card`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />

                            <MenuItemLink
                                to={`/personal-card-design`}
                                primaryText={translate(`Design Card`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />


                        </SubMenu>

                        <SubMenu
                            handleToggle={() => handleToggle('menuCardsCorporate')}
                            isOpen={state.menuCardsCorporate}
                            sidebarIsOpen={open}
                            name="Corporate"
                            icon={<RecentActorsRoundedIcon />}
                            dense={dense}>
                            <MenuItemLink
                                to={`/corporate-card-view`}
                                primaryText={translate(`View Card`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />

                            <MenuItemLink
                                to={`/card-design`}
                                primaryText={translate(`Card Request`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />


                        </SubMenu>
                    </SubMenu>

                    <MenuItemLink
                        to={`/settings`}
                        primaryText={translate(`Settings`)}
                        leftIcon={<SettingsIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                     />

                    {isXsmall && (
                        <>
                        <MenuItemLink
                            to="/configuration"
                            primaryText={translate('Change Password')}
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                        to={`/settings`}
                        primaryText={translate(`Settings`, {
                          smart_count: 2,
                        })}
                        leftIcon={<SettingsIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                      </>
                    )}
                    {isXsmall && logout}
                </> :

                <>
                    <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} style={{ marginTop: "2em" }} />

                    <SubMenu
                        handleToggle={() => handleToggle('menuCards')}
                        isOpen={state.menuCards}
                        sidebarIsOpen={open}
                        name="Cards"
                        icon={<RecentActorsRoundedIcon />}
                        dense={dense}
                    >
                        <SubMenu
                            handleToggle={() => handleToggle('menuCardsPersonal')}
                            isOpen={state.menuCardsPersonal}
                            sidebarIsOpen={open}
                            name="Personal"
                            icon={<RecentActorsRoundedIcon />}
                            dense={dense}>

                            <MenuItemLink
                                to={`/personal-card-view`}
                                primaryText={translate(`View Card`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />

                            <MenuItemLink
                                to={`/personal-card-design`}
                                primaryText={translate(`Design Card`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />


                        </SubMenu>
                        <SubMenu
                            handleToggle={() => handleToggle('menuCardsCorporate')}
                            isOpen={state.menuCardsCorporate}
                            sidebarIsOpen={open}
                            name="Corporate"
                            icon={<RecentActorsRoundedIcon />}
                            dense={dense}>
                            <MenuItemLink
                                to={`/corporate-card-view`}
                                primaryText={translate(`View Card`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />

                            <MenuItemLink
                                to={`/card-design`}
                                primaryText={translate(`Card Request`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<RecentActorsOutlinedIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense} />


                        </SubMenu>
                    </SubMenu>
                    <MenuItemLink
                        to={`/settings`}
                        primaryText={translate(`Settings`)}
                        leftIcon={<SettingsIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    {isXsmall && (
                        <>
                        <MenuItemLink
                            to="/configuration"
                            primaryText="change password"
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                        to={`/settings`}
                        primaryText={translate(`Settings`)}
                        leftIcon={<SettingsIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                     />
                        </>
                    )}
                    {isXsmall && logout}
                </>
            }

        </div>
    );
};

UserMenu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default UserMenu;
