import React, { useState, useEffect, useCallback } from 'react';
import Chart from './Chart';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import PersonalCard from './PersonalCard';
import BusinessCard from './BusinessCard';
import NewOrganizations from './NewOrganizations';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const UserDashboard = () => {
  const [state, setState] = useState({});
  const version = useVersion();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  //Get counts data for chart
  const fetchCount = useCallback(async () => {
    var numberPersonalCards, numberBusinessCards, numberOrganization;

    await dataProvider.getList(
      'user/count/details',
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'numberUser', order: 'DESC' }
      }
    )
      .then(res => {

        res.data.map(function (element) {
          numberPersonalCards = element.numberPersonalCards
          numberBusinessCards = element.numberBusinessCards
          numberOrganization = element.numberOrganization
        })


      })
      .catch(e => console.log('error : ' + e));
    ;

    setState(state => ({

      ...state,

      numberPersonalCards: numberPersonalCards,
      numberBusinessCards: numberBusinessCards,
      numberOrganization: numberOrganization

    }));
  }, [dataProvider])


  useEffect(() => {
    fetchCount()
  }, [version])

  const { numberPersonalCards, numberBusinessCards, numberOrganization } = state

  var data = {
    "numberPersonalCards": numberPersonalCards,
    "numberBusinessCards": numberBusinessCards,
    "numberOrganization": numberOrganization
  }

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <div style={{ marginBottom: '2em' }}>
          {!numberPersonalCards ? <p>LOADING</p> : <Chart value={data} />}
        </div>
        <div style={styles.flex}>
          {!numberPersonalCards ? <PersonalCard value={0} /> : <PersonalCard value={numberPersonalCards} />}
          {!numberBusinessCards ? <BusinessCard value={0} /> : <BusinessCard value={numberBusinessCards} />}

        </div>
        <div style={styles.singleCol}>

          <div style={styles.singleCol}>
            <NewOrganizations />

          </div>
        </div>
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn}>
      <div style={styles.singleCol}>
        {!numberPersonalCards ? <p>LOADING</p> : <Chart value={data} />}
      </div>
      <div style={styles.flex}>
        {!numberPersonalCards ? <PersonalCard value={0} /> : <PersonalCard value={numberPersonalCards} />}
        {!numberBusinessCards ? <BusinessCard value={0} /> : <BusinessCard value={numberBusinessCards} />}

      </div>
      <div style={styles.singleCol}>

        <div style={styles.singleCol}>
          <NewOrganizations />
        </div>
      </div>
    </div>
  ) : (
    <div style={styles.flex} >

      <div style={styles.leftCol}>
        <div style={styles.flex}>
          {!numberPersonalCards ? <PersonalCard value={0} /> : <PersonalCard value={numberPersonalCards} />}
          {!numberBusinessCards ? <BusinessCard value={0} /> : <BusinessCard value={numberBusinessCards} />}

        </div>
      </div>
      <div style={styles.rightCol}>

        <div style={styles.singleCol}>

          <NewOrganizations />
          
        </div>

      </div>
    </div>
  );

}
export default UserDashboard;