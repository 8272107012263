//Country constant

export const COUNTRY = [
    {
        "name": "Afghanistan",
        "id": "Afghanistan",
        "label": "AF"
    },
    {
        "name": "Åland Islands",
        "id": "Åland Islands",
        "label": "AX"
    },
    {
        "name": "Albania",
        "id": "Albania",
        "label": "AL"
    },
    {
        "name": "Algeria",
        "id": "Algeria",
        "label": "DZ"
    },
    {
        "name": "American Samoa",
        "id": "American Samoa",
        "label": "AS"
    },
    {
        "name": "AndorrA",
        "id": "AndorrA",
        "label": "AD"
    },
    {
        "name": "Angola",
        "id": "Angola",
        "label": "AO"
    },
    {
        "name": "Anguilla",
        "id": "Anguilla",
        "label": "AI"
    },
    {
        "name": "Antarctica",
        "id": "Antarctica",
        "label": "AQ"
    },
    {
        "name": "Antigua and Barbuda",
        "id": "Antigua and Barbuda",
        "label": "AG"
    },
    {
        "name": "Argentina",
        "id": "Argentina",
        "label": "AR"
    },
    {
        "name": "Armenia",
        "id": "Armenia",
        "label": "AM"
    },
    {
        "name": "Aruba",
        "id": "Aruba",
        "label": "AW"
    },
    {
        "name": "Australia",
        "id": "Australia",
        "label": "AU"
    },
    {
        "name": "Austria",
        "id": "Austria",
        "label": "AT"
    },
    {
        "name": "Azerbaijan",
        "id": "Azerbaijan",
        "label": "AZ"
    },
    {
        "name": "Bahamas",
        "id": "Bahamas",
        "label": "BS"
    },
    {
        "name": "Bahrain",
        "id": "Bahrain",
        "label": "BH"
    },
    {
        "name": "Bangladesh",
        "id": "Bangladesh",
        "label": "BD"
    },
    {
        "name": "Barbados",
        "id": "Barbados",
        "label": "BB"
    },
    {
        "name": "Belarus",
        "id": "Belarus",
        "label": "BY"
    },
    {
        "name": "Belgium",
        "id": "Belgium",
        "label": "BE"
    },
    {
        "name": "Belize",
        "id": "Belize",
        "label": "BZ"
    },
    {
        "name": "Benin",
        "id": "Benin",
        "label": "BJ"
    },
    {
        "name": "Bermuda",
        "id": "Bermuda",
        "label": "BM"
    },
    {
        "name": "Bhutan",
        "id": "Bhutan",
        "label": "BT"
    },
    {
        "name": "Bolivia",
        "id": "Bolivia",
        "label": "BO"
    },
    {
        "name": "Bosnia and Herzegovina",
        "id": "Bosnia and Herzegovina",
        "label": "BA"
    },
    {
        "name": "Botswana",
        "id": "Botswana",
        "label": "BW"
    },
    {
        "name": "Bouvet Island",
        "id": "Bouvet Island",
        "label": "BV"
    },
    {
        "name": "Brazil",
        "id": "Brazil",
        "label": "BR"
    },
    {
        "name": "British Indian Ocean Territory",
        "id": "British Indian Ocean Territory",
        "label": "IO"
    },
    {
        "name": "Brunei Darussalam",
        "id": "Brunei Darussalam",
        "label": "BN"
    },
    {
        "name": "Bulgaria",
        "id": "Bulgaria",
        "label": "BG"
    },
    {
        "name": "Burkina Faso",
        "id": "Burkina Faso",
        "label": "BF"
    },
    {
        "name": "Burundi",
        "id": "Burundi",
        "label": "BI"
    },
    {
        "name": "Cambodia",
        "id": "Cambodia",
        "label": "KH"
    },
    {
        "name": "Cameroon",
        "id": "Cameroon",
        "label": "CM"
    },
    {
        "name": "Canada",
        "id": "Canada",
        "label": "CA"
    },
    {
        "name": "Cape Verde",
        "id": "Cape Verde",
        "label": "CV"
    },
    {
        "name": "Cayman Islands",
        "id": "Cayman Islands",
        "label": "KY"
    },
    {
        "name": "Central African Republic",
        "id": "Central African Republic",
        "label": "CF"
    },
    {
        "name": "Chad",
        "id": "Chad",
        "label": "TD"
    },
    {
        "name": "Chile",
        "id": "Chile",
        "label": "CL"
    },
    {
        "name": "China",
        "id": "China",
        "label": "CN"
    },
    {
        "name": "Christmas Island",
        "id": "Christmas Island",
        "label": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "id": "Cocos (Keeling) Islands",
        "label": "CC"
    },
    {
        "name": "Colombia",
        "id": "Colombia",
        "label": "CO"
    },
    {
        "name": "Comoros",
        "id": "Comoros",
        "label": "KM"
    },
    {
        "name": "Congo",
        "id": "Congo",
        "label": "CG"
    },
    {
        "name": "Congo, The Democratic Republic of the",
        "id": "Congo, The Democratic Republic of the",
        "label": "CD"
    },
    {
        "name": "Cook Islands",
        "id": "Cook Islands",
        "label": "CK"
    },
    {
        "name": "Costa Rica",
        "id": "Costa Rica",
        "label": "CR"
    },
    {
        "name": "Cote D'Ivoire",
        "id": "Cote D'Ivoire",
        "label": "CI"
    },
    {
        "name": "Croatia",
        "id": "Croatia",
        "label": "HR"
    },
    {
        "name": "Cuba",
        "id": "Cuba",
        "label": "CU"
    },
    {
        "name": "Cyprus",
        "id": "Cyprus",
        "label": "CY"
    },
    {
        "name": "Czech Republic",
        "id": "Czech Republic",
        "label": "CZ"
    },
    {
        "name": "Denmark",
        "id": "Denmark",
        "label": "DK"
    },
    {
        "name": "Djibouti",
        "id": "Djibouti",
        "label": "DJ"
    },
    {
        "name": "Dominica",
        "id": "Dominica",
        "label": "DM"
    },
    {
        "name": "Dominican Republic",
        "id": "Dominican Republic",
        "label": "DO"
    },
    {
        "name": "Ecuador",
        "id": "Ecuador",
        "label": "EC"
    },
    {
        "name": "Egypt",
        "id": "Egypt",
        "label": "EG"
    },
    {
        "name": "El Salvador",
        "id": "El Salvador",
        "label": "SV"
    },
    {
        "name": "Equatorial Guinea",
        "id": "Equatorial Guinea",
        "label": "GQ"
    },
    {
        "name": "Eritrea",
        "id": "Eritrea",
        "label": "ER"
    },
    {
        "name": "Estonia",
        "id": "Estonia",
        "label": "EE"
    },
    {
        "name": "Ethiopia",
        "id": "Ethiopia",
        "label": "ET"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "id": "Falkland Islands (Malvinas)",
        "label": "FK"
    },
    {
        "name": "Faroe Islands",
        "id": "Faroe Islands",
        "label": "FO"
    },
    {
        "name": "Fiji",
        "id": "Fiji",
        "label": "FJ"
    },
    {
        "name": "Finland",
        "id": "Finland",
        "label": "FI"
    },
    {
        "name": "France",
        "id": "France",
        "label": "FR"
    },
    {
        "name": "French Guiana",
        "id": "French Guiana",
        "label": "GF"
    },
    {
        "name": "French Polynesia",
        "id": "French Polynesia",
        "label": "PF"
    },
    {
        "name": "French Southern Territories",
        "id": "French Southern Territories",
        "label": "TF"
    },
    {
        "name": "Gabon",
        "id": "Gabon",
        "label": "GA"
    },
    {
        "name": "Gambia",
        "id": "Gambia",
        "label": "GM"
    },
    {
        "name": "Georgia",
        "id": "Georgia",
        "label": "GE"
    },
    {
        "name": "Germany",
        "id": "Germany",
        "label": "DE"
    },
    {
        "name": "Ghana",
        "id": "Ghana",
        "label": "GH"
    },
    {
        "name": "Gibraltar",
        "id": "Gibraltar",
        "label": "GI"
    },
    {
        "name": "Greece",
        "id": "Greece",
        "label": "GR"
    },
    {
        "name": "Greenland",
        "id": "Greenland",
        "label": "GL"
    },
    {
        "name": "Grenada",
        "id": "Grenada",
        "label": "GD"
    },
    {
        "name": "Guadeloupe",
        "id": "Guadeloupe",
        "label": "GP"
    },
    {
        "name": "Guam",
        "id": "Guam",
        "label": "GU"
    },
    {
        "name": "Guatemala",
        "id": "Guatemala",
        "label": "GT"
    },
    {
        "name": "Guernsey",
        "id": "Guernsey",
        "label": "GG"
    },
    {
        "name": "Guinea",
        "id": "Guinea",
        "label": "GN"
    },
    {
        "name": "Guinea-Bissau",
        "id": "Guinea-Bissau",
        "label": "GW"
    },
    {
        "name": "Guyana",
        "id": "Guyana",
        "label": "GY"
    },
    {
        "name": "Haiti",
        "id": "Haiti",
        "label": "HT"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "id": "Heard Island and Mcdonald Islands",
        "label": "HM"
    },
    {
        "name": "Holy See (Vatican City State)",
        "id": "Holy See (Vatican City State)",
        "label": "VA"
    },
    {
        "name": "Honduras",
        "id": "Honduras",
        "label": "HN"
    },
    {
        "name": "Hong Kong",
        "id": "Hong Kong",
        "label": "HK"
    },
    {
        "name": "Hungary",
        "id": "Hungary",
        "label": "HU"
    },
    {
        "name": "Iceland",
        "id": "Iceland",
        "label": "IS"
    },
    {
        "name": "India",
        "id": "India",
        "label": "IN"
    },
    {
        "name": "Indonesia",
        "id": "Indonesia",
        "label": "ID"
    },
    {
        "name": "Iran, Islamic Republic Of",
        "id": "Iran, Islamic Republic Of",
        "label": "IR"
    },
    {
        "name": "Iraq",
        "id": "Iraq",
        "label": "IQ"
    },
    {
        "name": "Ireland",
        "id": "Ireland",
        "label": "IE"
    },
    {
        "name": "Isle of Man",
        "id": "Isle of Man",
        "label": "IM"
    },
    {
        "name": "Israel",
        "id": "Israel",
        "label": "IL"
    },
    {
        "name": "Italy",
        "id": "Italy",
        "label": "IT"
    },
    {
        "name": "Jamaica",
        "id": "Jamaica",
        "label": "JM"
    },
    {
        "name": "Japan",
        "id": "Japan",
        "label": "JP"
    },
    {
        "name": "Jersey",
        "id": "Jersey",
        "label": "JE"
    },
    {
        "name": "Jordan",
        "id": "Jordan",
        "label": "JO"
    },
    {
        "name": "Kazakhstan",
        "id": "Kazakhstan",
        "label": "KZ"
    },
    {
        "name": "Kenya",
        "id": "Kenya",
        "label": "KE"
    },
    {
        "name": "Kiribati",
        "id": "Kiribati",
        "label": "KI"
    },
    {
        "name": "Korea, Democratic People's Republic of",
        "id": "Korea, Democratic People's Republic of",
        "label": "KP"
    },
    {
        "name": "Korea, Republic of",
        "id": "Korea, Republic of",
        "label": "KR"
    },
    {
        "name": "Kuwait",
        "id": "Kuwait",
        "label": "KW"
    },
    {
        "name": "Kyrgyzstan",
        "id": "Kyrgyzstan",
        "label": "KG"
    },
    {
        "name": "Lao People's Democratic Republic",
        "id": "Lao People's Democratic Republic",
        "label": "LA"
    },
    {
        "name": "Latvia",
        "id": "Latvia",
        "label": "LV"
    },
    {
        "name": "Lebanon",
        "id": "Lebanon",
        "label": "LB"
    },
    {
        "name": "Lesotho",
        "id": "Lesotho",
        "label": "LS"
    },
    {
        "name": "Liberia",
        "id": "Liberia",
        "label": "LR"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "id": "Libyan Arab Jamahiriya",
        "label": "LY"
    },
    {
        "name": "Liechtenstein",
        "id": "Liechtenstein",
        "label": "LI"
    },
    {
        "name": "Lithuania",
        "id": "Lithuania",
        "label": "LT"
    },
    {
        "name": "Luxembourg",
        "id": "Luxembourg",
        "label": "LU"
    },
    {
        "name": "Macao",
        "id": "Macao",
        "label": "MO"
    },
    {
        "name": "Macedonia, The Former Yugoslav Republic of",
        "id": "Macedonia, The Former Yugoslav Republic of",
        "label": "MK"
    },
    {
        "name": "Madagascar",
        "id": "Madagascar",
        "label": "MG"
    },
    {
        "name": "Malawi",
        "id": "Malawi",
        "label": "MW"
    },
    {
        "name": "Malaysia",
        "id": "Malaysia",
        "label": "MY"
    },
    {
        "name": "Maldives",
        "id": "Maldives",
        "label": "MV"
    },
    {
        "name": "Mali",
        "id": "Mali",
        "label": "ML"
    },
    {
        "name": "Malta",
        "id": "Malta",
        "label": "MT"
    },
    {
        "name": "Marshall Islands",
        "id": "Marshall Islands",
        "label": "MH"
    },
    {
        "name": "Martinique",
        "id": "Martinique",
        "label": "MQ"
    },
    {
        "name": "Mauritania",
        "id": "Mauritania",
        "label": "MR"
    },
    {
        "name": "Mauritius",
        "id": "Mauritius",
        "label": "MU"
    },
    {
        "name": "Mayotte",
        "id": "Mayotte",
        "label": "YT"
    },
    {
        "name": "Mexico",
        "id": "Mexico",
        "label": "MX"
    },
    {
        "name": "Micronesia, Federated States of",
        "id": "Micronesia, Federated States of",
        "label": "FM"
    },
    {
        "name": "Moldova, Republic of",
        "id": "Moldova, Republic of",
        "label": "MD"
    },
    {
        "name": "Monaco",
        "id": "Monaco",
        "label": "MC"
    },
    {
        "name": "Mongolia",
        "id": "Mongolia",
        "label": "MN"
    },
    {
        "name": "Montserrat",
        "id": "Montserrat",
        "label": "MS"
    },
    {
        "name": "Morocco",
        "id": "Morocco",
        "label": "MA"
    },
    {
        "name": "Mozambique",
        "id": "Mozambique",
        "label": "MZ"
    },
    {
        "name": "Myanmar",
        "id": "Myanmar",
        "label": "MM"
    },
    {
        "name": "Namibia",
        "id": "Namibia",
        "label": "NA"
    },
    {
        "name": "Nauru",
        "id": "Nauru",
        "label": "NR"
    },
    {
        "name": "Nepal",
        "id": "Nepal",
        "label": "NP"
    },
    {
        "name": "Netherlands",
        "id": "Netherlands",
        "label": "NL"
    },
    {
        "name": "Netherlands Antilles",
        "id": "Netherlands Antilles",
        "label": "AN"
    },
    {
        "name": "New Caledonia",
        "id": "New Caledonia",
        "label": "NC"
    },
    {
        "name": "New Zealand",
        "id": "New Zealand",
        "label": "NZ"
    },
    {
        "name": "Nicaragua",
        "id": "Nicaragua",
        "label": "NI"
    },
    {
        "name": "Niger",
        "id": "Niger",
        "label": "NE"
    },
    {
        "name": "Nigeria",
        "id": "Nigeria",
        "label": "NG"
    },
    {
        "name": "Niue",
        "id": "Niue",
        "label": "NU"
    },
    {
        "name": "Norfolk Island",
        "id": "Norfolk Island",
        "label": "NF"
    },
    {
        "name": "Northern Mariana Islands",
        "id": "Northern Mariana Islands",
        "label": "MP"
    },
    {
        "name": "Norway",
        "id": "Norway",
        "label": "NO"
    },
    {
        "name": "Oman",
        "id": "Oman",
        "label": "OM"
    },
    {
        "name": "Pakistan",
        "id": "Pakistan",
        "label": "PK"
    },
    {
        "name": "Palau",
        "id": "Palau",
        "label": "PW"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "id": "Palestinian Territory, Occupied",
        "label": "PS"
    },
    {
        "name": "Panama",
        "id": "Panama",
        "label": "PA"
    },
    {
        "name": "Papua New Guinea",
        "id": "Papua New Guinea",
        "label": "PG"
    },
    {
        "name": "Paraguay",
        "id": "Paraguay",
        "label": "PY"
    },
    {
        "name": "Peru",
        "id": "Peru",
        "label": "PE"
    },
    {
        "name": "Philippines",
        "id": "Philippines",
        "label": "PH"
    },
    {
        "name": "Pitcairn",
        "id": "Pitcairn",
        "label": "PN"
    },
    {
        "name": "Poland",
        "id": "Poland",
        "label": "PL"
    },
    {
        "name": "Portugal",
        "id": "Portugal",
        "label": "PT"
    },
    {
        "name": "Puerto Rico",
        "id": "Puerto Rico",
        "label": "PR"
    },
    {
        "name": "Qatar",
        "id": "Qatar",
        "label": "QA"
    },
    {
        "name": "Reunion",
        "id": "Reunion",
        "label": "RE"
    },
    {
        "name": "Romania",
        "id": "Romania",
        "label": "RO"
    },
    {
        "name": "Russian Federation",
        "id": "Russian Federation",
        "label": "RU"
    },
    {
        "name": "RWANDA",
        "id": "RWANDA",
        "label": "RW"
    },
    {
        "name": "Saint Helena",
        "id": "Saint Helena",
        "label": "SH"
    },
    {
        "name": "Saint Kitts and Nevis",
        "id": "Saint Kitts and Nevis",
        "label": "KN"
    },
    {
        "name": "Saint Lucia",
        "id": "Saint Lucia",
        "label": "LC"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "id": "Saint Pierre and Miquelon",
        "label": "PM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "id": "Saint Vincent and the Grenadines",
        "label": "VC"
    },
    {
        "name": "Samoa",
        "id": "Samoa",
        "label": "WS"
    },
    {
        "name": "San Marino",
        "id": "San Marino",
        "label": "SM"
    },
    {
        "name": "Sao Tome and Principe",
        "id": "Sao Tome and Principe",
        "label": "ST"
    },
    {
        "name": "Saudi Arabia",
        "id": "Saudi Arabia",
        "label": "SA"
    },
    {
        "name": "Senegal",
        "id": "Senegal",
        "label": "SN"
    },
    {
        "name": "Serbia and Montenegro",
        "id": "Serbia and Montenegro",
        "label": "CS"
    },
    {
        "name": "Seychelles",
        "id": "Seychelles",
        "label": "SC"
    },
    {
        "name": "Sierra Leone",
        "id": "Sierra Leone",
        "label": "SL"
    },
    {
        "name": "Singapore",
        "id": "Singapore",
        "label": "SG"
    },
    {
        "name": "Slovakia",
        "id": "Slovakia",
        "label": "SK"
    },
    {
        "name": "Slovenia",
        "id": "Slovenia",
        "label": "SI"
    },
    {
        "name": "Solomon Islands",
        "id": "Solomon Islands",
        "label": "SB"
    },
    {
        "name": "Somalia",
        "id": "Somalia",
        "label": "SO"
    },
    {
        "name": "South Africa",
        "id": "South Africa",
        "label": "ZA"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "id": "South Georgia and the South Sandwich Islands",
        "label": "GS"
    },
    {
        "name": "Spain",
        "id": "Spain",
        "label": "ES"
    },
    {
        "name": "Sri Lanka",
        "id": "Sri Lanka",
        "label": "LK"
    },
    {
        "name": "Sudan",
        "id": "Sudan",
        "label": "SD"
    },
    {
        "name": "Suriname",
        "id": "Suriname",
        "label": "SR"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "id": "Svalbard and Jan Mayen",
        "label": "SJ"
    },
    {
        "name": "Swaziland",
        "id": "Swaziland",
        "label": "SZ"
    },
    {
        "name": "Sweden",
        "id": "Sweden",
        "label": "SE"
    },
    {
        "name": "Switzerland",
        "id": "Switzerland",
        "label": "CH"
    },
    {
        "name": "Syrian Arab Republic",
        "id": "Syrian Arab Republic",
        "label": "SY"
    },
    {
        "name": "Taiwan, Province of China",
        "id": "Taiwan, Province of China",
        "label": "TW"
    },
    {
        "name": "Tajikistan",
        "id": "Tajikistan",
        "label": "TJ"
    },
    {
        "name": "Tanzania, United Republic of",
        "id": "Tanzania, United Republic of",
        "label": "TZ"
    },
    {
        "name": "Thailand",
        "id": "Thailand",
        "label": "TH"
    },
    {
        "name": "Timor-Leste",
        "id": "Timor-Leste",
        "label": "TL"
    },
    {
        "name": "Togo",
        "id": "Togo",
        "label": "TG"
    },
    {
        "name": "Tokelau",
        "id": "Tokelau",
        "label": "TK"
    },
    {
        "name": "Tonga",
        "id": "Tonga",
        "label": "TO"
    },
    {
        "name": "Trinidad and Tobago",
        "id": "Trinidad and Tobago",
        "label": "TT"
    },
    {
        "name": "Tunisia",
        "id": "Tunisia",
        "label": "TN"
    },
    {
        "name": "Turkey",
        "id": "Turkey",
        "label": "TR"
    },
    {
        "name": "Turkmenistan",
        "id": "Turkmenistan",
        "label": "TM"
    },
    {
        "name": "Turks and Caicos Islands",
        "id": "Turks and Caicos Islands",
        "label": "TC"
    },
    {
        "name": "Tuvalu",
        "id": "Tuvalu",
        "label": "TV"
    },
    {
        "name": "Uganda",
        "id": "Uganda",
        "label": "UG"
    },
    {
        "name": "Ukraine",
        "id": "Ukraine",
        "label": "UA"
    },
    {
        "name": "United Arab Emirates",
        "id": "United Arab Emirates",
        "label": "AE"
    },
    {
        "name": "United Kingdom",
        "id": "United Kingdom",
        "label": "GB"
    },
    {
        "name": "United States",
        "id": "United States",
        "label": "US"
    },
    {
        "name": "United States Minor Outlying Islands",
        "id": "United States Minor Outlying Islands",
        "label": "UM"
    },
    {
        "name": "Uruguay",
        "id": "Uruguay",
        "label": "UY"
    },
    {
        "name": "Uzbekistan",
        "id": "Uzbekistan",
        "label": "UZ"
    },
    {
        "name": "Vanuatu",
        "id": "Vanuatu",
        "label": "VU"
    },
    {
        "name": "Venezuela",
        "id": "Venezuela",
        "label": "VE"
    },
    {
        "name": "Viet Nam",
        "id": "Viet Nam",
        "label": "VN"
    },
    {
        "name": "Virgin Islands, British",
        "id": "Virgin Islands, British",
        "label": "VG"
    },
    {
        "name": "Virgin Islands, U.S.",
        "id": "Virgin Islands, U.S.",
        "label": "VI"
    },
    {
        "name": "Wallis and Futuna",
        "id": "Wallis and Futuna",
        "label": "WF"
    },
    {
        "name": "Western Sahara",
        "id": "Western Sahara",
        "label": "EH"
    },
    {
        "name": "Yemen",
        "id": "Yemen",
        "label": "YE"
    },
    {
        "name": "Zambia",
        "id": "Zambia",
        "label": "ZM"
    },
    {
        "name": "Zimbabwe",
        "id": "Zimbabwe",
        "label": "ZW"
    }
]
