import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate, useDataProvider, useNotify, useVersion, Button, Link } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Accessible from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToAppOutlined';
import LockOpen from '@material-ui/icons/LockOpen';
import { NODE_API } from '../config'
import axios from 'axios';
import { IconButton, Menu, Tooltip, useMediaQuery } from '@material-ui/core';
import { history } from '../helpers/history';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',


    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((_, ref) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const notify = useNotify();
    const version = useVersion();


    const getOrgDetails = useCallback(async () => {

        let username;

        let details = []

        let user = JSON.parse(sessionStorage.getItem('user'));
        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'GET',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        await axios(`${NODE_API}/user`, requestOptions)

            .then(res => {

                details = res.data

                username = res.data.username

                return res;

            }).catch(e => {

                console.log('error : ' + e)

                notify(`Something went wrong try again later`, { type: 'warning' })

            });
        ;


        setState(state => ({

            ...state,

            username: username

        }));


    }, [dataProvider])


    useEffect(() => {
        getOrgDetails()
    }, [version])




    return (
        <>
            {state.username ?
                <MenuItemLink
                    ref={ref}
                    to=""
                    primaryText={`${state.username}`}
                    leftIcon={<AccountCircleIcon />} />

                :
                <MenuItemLink
                    ref={ref}
                    to=""
                    primaryText={"User"}
                    leftIcon={<AccountCircleIcon />} />}

            <MenuItemLink
                ref={ref}
                to="/configuration"
                primaryText={translate('Change Password')}
                leftIcon={<Accessible />}
            />
        </>
    );
});



const CustomUserMenu = (props) => {

    const logOut = () => {

        sessionStorage.removeItem('user');
        history.push('/login')

    }

    return (

        <>

            <Link title='Change Password' to={'/configuration'} >
                <Tooltip title="Change Password">
                    <IconButton placeholder='Change Password' style={{ color: 'white' }} >
                        <LockOpen />
                    </IconButton>
                </Tooltip>

            </Link>

            <Tooltip title="Log Out">
                <IconButton placeholder='Log Out' onClick={logOut} style={{ color: 'white' }} ><ExitToApp /></IconButton>
            </Tooltip>
        </>)
};

const CustomAppBar = props => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));



    return (
        isSmall && isXSmall ?
            <AppBar {...props} userMenu={<CustomUserMenu />} style={{ backgroundColor: "#4caf50" }}>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                />
            </AppBar>
            :
            <AppBar {...props} userMenu={<CustomUserMenu />} style={{ backgroundColor: "#4caf50" }}>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                />
            </AppBar>
    );
};

export default CustomAppBar;
